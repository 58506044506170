import { observer } from "mobx-react-lite";
import React, {useState, useEffect} from "react";
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, TextField, Button, MenuItem, Card, CardMedia } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom'
import { useStore } from "../../store/rootStore";
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


const ProfileEdit = () => {
    
    const [imageUrl, setImageUrl] = useState<string|null>(null)
    const [categories, setCategories] = useState<any[]>([])
    const [units, setUnits] = useState<any[]>([]);
    const {rootStore: {authStore, settingStore}} = useStore();
    const { id } = useParams()
    const [t, i18n] = useTranslation("global");
    const [loading, setLoading] = useState(false);
    const [defaultUnits, setDefaultUnits] = useState<any[]>([]);
    const [preUnits, setPreUnits] = useState<any[]>([]);

    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const token = authStore.token ?? '';

    const navigate = useNavigate();

    const updateValidationSchema = Yup.object().shape({
        id: Yup.string(),
        role: Yup.string().required('Category is required'),
        name: Yup.string().required(t('formValidation.name')),
        email: Yup.string().email(t('formValidation.InvalidEmail')).required(t('formValidation.required')),
        phone: Yup.string().required(t('formValidation.phone_number'))
          .min(11, t('formValidation.phone_len'))
          .max(11, t('formValidation.phone_len')),
        current_password: Yup.string().nullable(),
        password: Yup.string().nullable(),
        confirmPassword: Yup.string().nullable()
            .oneOf([Yup.ref('password'), null], t('formValidation.nomatchPassword')), 
        // photo: Yup.mixed().test('fileType', 'Unsupported file format', (value:any) => {
        //     if (value !== ""){
        //     const supportedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
        //     return supportedFormats.includes(value.type);
        //     }
        //     return true; // skip this
        // }).test('fileSize', 'File size is too large (max: 5000KB)', (value:any) => {
        //     if (value !== "") {
        //     return value.size <= 5000000; // 5000KB in bytes
        //     }
        //     return true; // skip this
        // }),
    })

    const { control:updateControl, handleSubmit:updateHandleSubmit, formState: { errors:updateErrors }, reset:updateReset, setValue:updateSetValue, getValues:updateGetValue, setError:updateSetError } = useForm({
        resolver: yupResolver(updateValidationSchema),
        defaultValues: {
            id: "",
            role: "",
            name: "",
            email: "",
            phone: "",
            current_password: "",
            password: "",
            confirmPassword: "",
            // photo: ""
        }
    })

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleClickShowCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
    const handleMouseDownPassword = (event:any) => event.preventDefault();
    const handleMouseDownCurrentPassword = (event:any) => event.preventDefault();
    const handleMouseConfirmDownPassword = (event:any) => event.preventDefault();

    const onSubmit = async (data:any) => {
        setLoading(true);
        try {
            // if(id){
                // const formData = new FormData();
                // Object.keys(data).map(key => {
                //     if(key=="photo"){
                //     if(data[key] !== "") formData.append(key, data[key]);
                //     }else{
                //     formData.append(key, data[key]);
                //     }
                // })
                const resData = await settingStore.updateProfileData(data)
                if (resData){
                    console.log(resData);
                    updateReset()
                    navigate('/app/profile')
                }
            // }
            setLoading(false);
        } catch (error:any) {
            Object.keys(error?.data).map((e:any) => {
                updateSetError(e, {
                type: 'manual', // Use 'manual' for manually triggered errors
                message: error?.data[e],
                });
            })
            setLoading(false);
        }
    }
  
    
    const initTable = async () => {
        setLoading(true);
        try {
            const resData = await settingStore.getProfileDataForUpdate(token);
            console.log(resData);
            let {photo, ...formData} = resData
            setImageUrl(process.env.REACT_APP_STORAGE_URL +"/"+photo)
            updateReset(formData)
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    function getLastUrlParam(url:any) {
        const params = url?.split('/').pop() || "null";
        const lastParam = params[params.length - 1]; // Get the last element
        return lastParam;
    }

    useEffect(() => {
        initTable();
    }, [id])
    
    const roles = [
        {id:0, name:'Admin'},
        {id:1, name:'Manager'},
        {id:2, name:'Salesman'}
    ]

    return (
        <>
        {loading ? 
        <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
            <LinearProgress color="secondary"/>
        </Box>
        : ''
        }
        
        <Box sx={{ width: '100%' }}>
            <h4>{t('users.update')}</h4>
            <form onSubmit={updateHandleSubmit(onSubmit)} autoComplete="off">
            <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 12, md: 12 }}>
            <Grid item xs={12}>
                <Controller
                name="role"
                control={updateControl}
                render={({ field }) => (
                    <TextField
                    {...field}
                    fullWidth
                    select
                    id="role"
                    label={t("role")}
                    variant="filled"
                    error={!!updateErrors.role}
                    helperText={updateErrors.role?.message}
                    sx={{
                        margin: 0, // Outer margin
                        '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            paddingleft: '8px', // Input padding
                            paddingTop:'0px',
                            fontSize: '12px',
                        },
                        '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                        },
                        }}
                    >
                    {roles.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                        {option.name}
                        </MenuItem>
                    ))}
                    </TextField>
                )}
                />
            </Grid>
            <Grid item xs={12}>
                <Controller
                name="name"
                control={updateControl}
                render={({ field }) => (
                    <TextField
                    {...field}
                    fullWidth
                    id="name"
                    label={t('name')}
                    variant="filled"
                    error={!!updateErrors.name}
                    helperText={updateErrors.name?.message}
                    autoComplete="off"
                    sx={{
                        margin: 0, // Outer margin
                        '& .MuiInputBase-root': {
                        height: '40px', // Input height
                        padding: '0 0px', // Input padding
                        fontSize: '14px'
                        },
                        '& .MuiInputLabel-root': {
                        padding: '0',
                        marginTop: '-5px',
                        fontSize: '14px',
                        },
                    }}
                    />
                )}
                />
            </Grid>
            <Grid item xs={12}>
                {updateGetValue('email')}
            </Grid>
            <Grid item xs={12}>
                <Controller
                name="phone"
                control={updateControl}
                render={({ field }) => (
                    <TextField
                    {...field}
                    fullWidth
                    id="phone_number"
                    label={t('phone')}
                    variant="filled"
                    error={!!updateErrors.phone}
                    helperText={updateErrors.phone?.message}
                    autoComplete="off"
                    sx={{
                        margin: 0, // Outer margin
                        '& .MuiInputBase-root': {
                        height: '40px', // Input height
                        padding: '0 0px', // Input padding
                        fontSize: '14px'
                        },
                        '& .MuiInputLabel-root': {
                        padding: '0',
                        marginTop: '-5px',
                        fontSize: '14px',
                        },
                    }}
                    />
                )}
                />
            </Grid>
            <Grid item xs={12}>
            <Controller
            name="current_password"
            control={updateControl}
            render={({ field }) => (
                <TextField
                {...field}
                fullWidth
                type={showCurrentPassword ? 'text' : 'password'}
                id="current_password"
                label={t('currentPassword')}
                variant="filled"
                error={!!updateErrors.current_password}
                helperText={updateErrors.current_password?.message == 'currentPasswordNotMatch' ? t('formValidation.currentPasswordNotMatch'): updateErrors.current_password?.message}
                autoComplete="off"
                sx={{
                    margin: 0, // Outer margin
                    '& .MuiInputBase-root': {
                    height: '40px', // Input height
                    padding: '0 0px', // Input padding
                    fontSize: '14px'
                    },
                    '& .MuiInputLabel-root': {
                    padding: '0',
                    marginTop: '-5px',
                    fontSize: '14px',
                    },
                }}
                InputProps={{
                    endAdornment: (
                    <InputAdornment position="end"
                    >
                        <IconButton
                        onClick={handleClickShowCurrentPassword}
                        onMouseDown={handleMouseDownCurrentPassword}
                        edge="end"
                        sx={{ marginRight: '3px' }}
                        >
                        {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    ),
                }}
                />
            )}
            />
        </Grid>
            <Grid item xs={12}>
            <Controller
            name="password"
            control={updateControl}
            render={({ field }) => (
                <TextField
                {...field}
                fullWidth
                type={showPassword ? 'text' : 'password'}
                id="password"
                label={t('newPassword')}
                variant="filled"
                error={!!updateErrors.password}
                helperText={updateErrors.password?.message == 'passwordLength' ? t('formValidation.passwordLength'): updateErrors.password?.message}
                autoComplete="off"
                sx={{
                    margin: 0, // Outer margin
                    '& .MuiInputBase-root': {
                    height: '40px', // Input height
                    padding: '0 0px', // Input padding
                    fontSize: '14px'
                    },
                    '& .MuiInputLabel-root': {
                    padding: '0',
                    marginTop: '-5px',
                    fontSize: '14px',
                    },
                }}
                InputProps={{
                    endAdornment: (
                    <InputAdornment position="end"
                    >
                        <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        sx={{ marginRight: '3px' }}
                        >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    ),
                }}
                />
            )}
            />
        </Grid>
        <Grid item xs={12}>
            <Controller
            name="confirmPassword"
            control={updateControl}
            render={({ field }) => (
                <TextField
                {...field}
                fullWidth
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                label={t('confirmPassword')}
                variant="filled"
                error={!!updateErrors.confirmPassword}
                helperText={updateErrors.confirmPassword?.message}
                autoComplete="off"
                sx={{
                    margin: 0, // Outer margin
                    '& .MuiInputBase-root': {
                    height: '40px', // Input height
                    padding: '0 0px', // Input padding
                    fontSize: '14px'
                    },
                    '& .MuiInputLabel-root': {
                    padding: '0',
                    marginTop: '-5px',
                    fontSize: '14px',
                    },
                }}
                InputProps={{
                    endAdornment: (
                    <InputAdornment position="end"
                    >
                        <IconButton
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseConfirmDownPassword}
                        edge="end"
                        sx={{ marginRight: '3px' }}
                        >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    ),
                }}
                />
            )}
            />
        </Grid>
            </Grid>
                <Button sx={{ mt: 2 }} variant="contained" onClick={() => navigate(-1)}>
                {t('back')}
                </Button>
                <Button sx={{ mt: 2, ml: 2 }} type="submit" variant="contained" color="success">
                {t('update')}
                </Button>
            </form>
        </Box>
        </>
    )
}

export default observer(ProfileEdit);