import { observer } from "mobx-react-lite";
import React, {useState, useEffect} from "react";
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, TextField, FormControlLabel, Checkbox, Button, CardMedia, Card, MenuItem, Select, Chip } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom'
import { useStore } from '../../../store/rootStore';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';


const ProductCreate = () => {

    const [imageUrl, setImageUrl] = useState<string|null>(null);
    const [categories, setCategories] = useState<any[]>([]);
    const [units, setUnits] = useState<any[]>([]);
    const { rootStore: { productStore } } = useStore();
    const { createData, initCategories, initUnits } = productStore;
    const [t, i18n] = useTranslation("global");
    const [loading, setLoading] = useState(false);
    const [defaultUnits, setDefaultUnits] = useState<any[]>([]);

    const navigate = useNavigate();

    

    const validationSchema = Yup.object().shape({
        product_code: Yup.string(),
        name: Yup.string().required('Product name is required'),
        category_id: Yup.string().required('Category is required'),
        units: Yup.array().min(1, 'At least one option must be selected')
        .required('At least one option must be selected'),
        default_unit: Yup.string().required('You must select an option'),
        purchase_price: Yup.number().required('Price is required').min(0, 'Minimum price is 0'),
        price: Yup.number().required('Price is required').min(0, 'Minimum price is 0'),
        image: Yup.mixed().test('fileType', 'Unsupported file format', (value:any) => {
            if (value !== ""){
            const supportedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
            return supportedFormats.includes(value.type);
            }
            return true; // skip this
        }).test('fileSize', 'File size is too large (max: 5000KB)', (value:any) => {
            if (value !== "") {
            return value.size <= 5000000; // 5000KB in bytes
            }
            return true; // skip this
        }),
        alert_limit: Yup.number().required('Alert limit is required').min(0, 'Minimum alert limit is 0'),
    })

    const { control, register, handleSubmit, setValue, formState: { errors }, reset, setError } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            product_code: "",
            name: "",
            category_id: "",
            units:[],
            default_unit: "",
            purchase_price: 0,
            price: 0,
            image: "",
            alert_limit: 0,
        }
    })

    const onSubmit = async (data:any) => {
        // alert('ok');
        // console.log(data);
        
        setLoading(true);
        try {
            const formData = new FormData();
            Object.keys(data).map(key => {
              formData.append(key, data[key]);
            })
            const resData = await createData(formData)
            // console.log(resData);
            if (resData){
              reset()
              navigate('..')
            }
            setLoading(false);
        } catch (error:any) {
            Object.keys(error?.data).map((e:any) => {
                setError(e, {
                type: 'manual', // Use 'manual' for manually triggered errors
                message: error?.data[e],
                });
            })
            setLoading(false);
        }
          
    }

    const initCategoriesData = async () => {
        try{
            setLoading(true);
            const resData = await initCategories();
            if(resData){
                // console.log(resData);
                setLoading(false);
                setCategories(resData.data.categories)
            }
            
        }catch(e: any){
            setLoading(false);
            console.log(e);
        }
    }

    const initUnitsData = async () => {
        try{
            setLoading(true);
            const resData = await initUnits();
            if(resData){
                setLoading(false);
                // console.log(resData);
                setUnits(resData.data.units)
            }
            
        }catch(e: any){
            setLoading(false);
            console.log(e);
        }
    }

    useEffect(() => {
        initCategoriesData()
        initUnitsData()
    }, [])

    return (
        <>
        {loading ? 
        <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
            <LinearProgress color="secondary"/>
        </Box>
        : ''
        }
        
        <Box sx={{ width: '100%' }}>
            <h4>{t('products.create')}</h4>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={2}>
                    <Controller
                    name="product_code"
                    control={control}
                    render={({ field }) => (
                        <TextField
                        {...field}
                        fullWidth
                        id="product_code"
                        label={t("productCode")}
                        variant="filled"
                        error={!!errors.product_code}
                        helperText={errors.product_code?.message}
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            paddingleft: '8px', // Input padding
                            paddingTop:'0px',
                            fontSize: '12px',
                            },
                            '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                            },
                        }}
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={4}>
                <Controller
                name="name"
                control={control}
                render={({ field }) => (
                    <TextField
                    {...field}
                    fullWidth
                    id="name"
                    label={t("name")}
                    variant="filled"
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    sx={{
                        margin: 0, // Outer margin
                        '& .MuiInputBase-root': {
                          height: '40px', // Input height
                          paddingleft: '8px', // Input padding
                          paddingTop:'0px',
                          fontSize: '12px',
                        },
                        '& .MuiInputLabel-root': {
                          padding: '0',
                          marginTop: '-5px',
                          fontSize: '14px',
                        },
                      }}
                    />
                )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                name="category_id"
                control={control}
                render={({ field }) => (
                    <TextField
                    {...field}
                    fullWidth
                    select
                    id="category_id"
                    label={t("category")}
                    variant="filled"
                    error={!!errors.category_id}
                    helperText={errors.category_id?.message}
                    sx={{
                        margin: 0, // Outer margin
                        '& .MuiInputBase-root': {
                          height: '40px', // Input height
                          paddingleft: '8px', // Input padding
                          paddingTop:'0px',
                          fontSize: '12px',
                        },
                        '& .MuiInputLabel-root': {
                          padding: '0',
                          marginTop: '-5px',
                          fontSize: '14px',
                        },
                      }}
                    >
                    {categories.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                        {option.name}
                        </MenuItem>
                    ))}
                    </TextField>
                )}
                />
            </Grid>
            <Grid item xs={6}>
                <label htmlFor="">{t('unit')}</label><br/>
                <Controller
                name="units"
                control={control}
                render={({ field }) => (
                    <>
                    {
                        units.map((unit)=>(
                            <label>
                                <input
                                type="checkbox"
                                value={unit.id}
                                onChange={(e) => {
                                    setValue('default_unit','');
                                    const value = e.target.value;
                                    field.onChange(
                                    e.target.checked
                                        ? [...field.value, value]
                                        : field.value.filter((val) => val !== value)
                                    );
                                    var elements = document.getElementsByTagName("input");

                                    
                                    if(e.target.checked){
                                        var defaultObject = {id:value,text:unit.name};
                                        setDefaultUnits([...defaultUnits, defaultObject]);
                                    }else{
                                        setDefaultUnits(items=>items.filter(item=>item.id!==value));
                                    }
                                }}
                                checked={field.value.includes(unit.id.toString())}
                                />
                                {unit.name}
                            </label>
                        ))
                    
                    }
                    </>
                )}
                />
                {errors.units && <p style={{color:'#d32f2f', fontFamily: "sans-serif", fontWeight:'400', fontSize: '0.75rem', marginTop:'4px', textAlign:'left', marginLeft:'14px', marginRight:'14px', letterSpacing:'0.03333em'}}>{errors.units.message}</p>}
            </Grid>
            <Grid item xs={6}>
                <label htmlFor="">{t('defaultUnit')}</label><br/>
                {defaultUnits.map((defaultUnit)=>(
                    <>
                    <label>
                    <input
                        type="radio"
                        value={defaultUnit.id}
                        {...register('default_unit')}
                    />
                    {defaultUnit.text}
                    </label>
                    </>
                ))}
                {errors.default_unit && <p style={{color:'#d32f2f', fontFamily: "sans-serif", fontWeight:'400', fontSize: '0.75rem', marginTop:'4px', textAlign:'left', marginLeft:'14px', marginRight:'14px', letterSpacing:'0.03333em'}}>{errors.default_unit.message}</p>}
            </Grid>
            <Grid item xs={3}>
                <Controller
                name="purchase_price"
                control={control}
                render={({ field }) => (
                    <TextField
                    {...field}
                    fullWidth
                    id="purchase_price"
                    label={t("purchasePrice")}
                    variant="filled"
                    error={!!errors.purchase_price}
                    helperText={errors.purchase_price?.message}
                    sx={{
                        margin: 0, // Outer margin
                        '& .MuiInputBase-root': {
                          height: '40px', // Input height
                          padding: '0 0px', // Input padding
                          fontSize: '14px'
                        },
                        '& .MuiInputLabel-root': {
                          padding: '0',
                          marginTop: '-5px',
                          fontSize: '14px',
                        },
                      }}
                    />
                )}
                />
            </Grid>
            <Grid item xs={3}>
                <Controller
                name="price"
                control={control}
                render={({ field }) => (
                    <TextField
                    {...field}
                    fullWidth
                    id="price"
                    label={t("salePrice")}
                    variant="filled"
                    error={!!errors.price}
                    helperText={errors.price?.message}
                    sx={{
                        margin: 0, // Outer margin
                        '& .MuiInputBase-root': {
                          height: '40px', // Input height
                          padding: '0 0px', // Input padding
                          fontSize: '14px'
                        },
                        '& .MuiInputLabel-root': {
                          padding: '0',
                          marginTop: '-5px',
                          fontSize: '14px',
                        },
                      }}
                    />
                )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                name="alert_limit"
                control={control}
                render={({ field }) => (
                    <TextField
                    {...field}
                    fullWidth
                    id="alert_limit"
                    label={t("alertLimit")}
                    variant="filled"
                    error={!!errors.alert_limit}
                    helperText={errors.alert_limit?.message}
                    sx={{
                        margin: 0, // Outer margin
                        '& .MuiInputBase-root': {
                          height: '40px', // Input height
                          padding: '0 0px', // Input padding
                          fontSize: '14px'
                        },
                        '& .MuiInputLabel-root': {
                          padding: '0',
                          marginTop: '-5px',
                          fontSize: '14px',
                        },
                      }}
                    />
                )}
                />
            </Grid>
            <Grid item xs={6}>
                {imageUrl && <Card sx={{ maxWidth: 100, my: 1 }}>
                <CardMedia
                component="img"
                alt="green iguana"
                height="auto"
                image={imageUrl ?? ""}
                />
                </Card>}
                <Controller
                name="image"
                control={control}
                render={({ field }) => (
                    <TextField
                    fullWidth
                    id="image"
                    type='file'
                    label="Zip code"
                    variant="filled"
                    focused
                    onChange={(e: any) => {
                        field.onChange(e.target.files[0])
                        e.target.files.length > 0 ? setImageUrl(URL.createObjectURL(e.target.files[0])) : setImageUrl(null)
                    }}
                    error={!!errors.image}
                    helperText={errors.image?.message}
                    />
                )}
                />
            </Grid>
                </Grid>
                <Button sx={{ mt: 2 }} variant="contained" onClick={() => navigate(-1)}>
                {t('back')}
                </Button>
                <Button sx={{ mt: 2, ml: 2 }} type="submit" variant="contained" color="success">
                {t('save')}
                </Button>
            </form>
        </Box>
        </>
    )
}

export default observer(ProductCreate);