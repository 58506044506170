import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useStore } from '../../../store/rootStore';
import { observer } from 'mobx-react-lite';
import LinearProgress from '@mui/material/LinearProgress';
import { Button, Grid, TextField, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


const ProductCategory = () => {

    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(false);
    const [t, i18n] = useTranslation("global");
    const [dialogCreateOpen, setDialogCreateOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [updateId, setUpdateId] = useState(null);

    const {rootStore: {settingStore, authStore}} = useStore();

    const createValidationSchema = Yup.object().shape({
        id: Yup.string(),
        name: Yup.string().required(t('formValidation.name')),
    })

    const { control:createControl, handleSubmit:createHandleSubmit, formState: { errors:createErrors }, reset:createReset, setValue:createSetValue, setError:createSetError } = useForm({
        resolver: yupResolver(createValidationSchema),
        defaultValues: {
            name: "",
        }
    })

    const updateValidationSchema = Yup.object().shape({
        id: Yup.string(),
        name: Yup.string().required(t('formValidation.name')),
    })

    const { control:updateControl, handleSubmit:updateHandleSubmit, formState: { errors:updateErrors }, reset:updateReset, setValue:updateSetValue, setError:updateSetError } = useForm({
        resolver: yupResolver(updateValidationSchema),
        defaultValues: {
            id: "",
            name: "",
        }
    })

    const initTable = async () => {
        setLoading(true);
        try {
            const resData = await settingStore.fetchCategoryList();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    useEffect(()=>{
        initTable();
    }, []);

    const onUpdateCategorySubmit = async (data:any) => {
      setLoading(true);
      try {
          if(updateId !== null){
              const resData = await settingStore.updateCategoryData(data)
              console.log(resData);
              updateReset({id:'',name:''});
              handleUpdateCategoryDialogClose();
              initTable();
          }
          setLoading(false);
      } catch (error:any) {
          Object.keys(error?.data).map((e:any) => {
          updateSetError(e, {
              type: 'manual', // Use 'manual' for manually triggered errors
              message: error?.data[e],
          });
          })
          setLoading(false);
      }
    }

    const onCreateCategorySubmit = async (data:any) => {
      setLoading(true);
      try {
          const resData = await settingStore.createCategoryData(data)
          console.log(resData);
          createReset({name:''});
          handleCreateCategoryDialogClose();
          setLoading(false);
          initTable();
      } catch (error:any) {
          Object.keys(error?.data).map((e:any) => {
          createSetError(e, {
              type: 'manual', // Use 'manual' for manually triggered errors
              message: error?.data[e],
          });
          })
          setLoading(false);
      }
    }

    const handleUpdateCategoryDialogClose = () => {
        
        setDialogOpen(false);
    };

    const handleUpdateCategoryOpenDialog = () => {
        
        setDialogOpen(true);
    }

    const handleCreateCategoryDialogClose = () => {
        createReset({name:''});
        setDialogCreateOpen(false);
    };

    const handleCreateCategoryOpenDialog = () => {
        createReset({name:''});
        setDialogCreateOpen(true);
    }
    
    
    const confirmDelete = async(id:any)=>{
      setLoading(true);
      try {
        const resData = await settingStore.deleteCategoryData(id);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }

    const deleteData = (params:any) => {

      settingStore.rootStore.dialogStore.openDialog({
        confirmFn: () => confirmDelete(params.id),
        dialogText: t("categories.deleteConfirmText")
      })
      // customerStore.deleteDialog(params);
      // setLoading(false);
    }
    

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.toLowerCase();
      setSearchText(event.target.value);

      settingStore.categoryRowData = settingStore.searchCategoryData.filter((row)=>{
        return row.name.toLowerCase().includes(value);
      })
    };
    
    const editData = async(params:any) => {
      updateReset({name:''});
      setUpdateId(params.id);
      handleUpdateCategoryOpenDialog();
      setLoading(true);
      try {
          
          const resData = await settingStore.getCategoryData(params.id)
          updateReset(resData.data.category)
          updateSetValue('id', params.id);
          setLoading(false);
      } catch (error) {
          // Handle errors, e.g., show an error message
          setLoading(false);
          console.error('Error while fetching data:', error);
      }

      console.log(params.id);

    }

  return (
    <>
    {loading ? 
    <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
        <LinearProgress color="secondary"/>
    </Box>
    : ''
    }
    <Dialog open={dialogOpen} onClose={handleUpdateCategoryDialogClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '80%',            // Custom width
          maxWidth: '500px',       // Custom max-width
        },
      }}
    >
      <DialogTitle>{t('categories.update')}</DialogTitle>
      <form onSubmit={updateHandleSubmit(onUpdateCategorySubmit)}>
      <DialogContent>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 12, md: 12 }}>
      <Grid item xs={12}>
          <Controller
          name="name"
          control={updateControl}
          render={({ field }) => (
              <TextField
              {...field}
              fullWidth
              id="name"
              label={t('name')}
              variant="filled"
              error={!!updateErrors.name}
              helperText={updateErrors.name?.message}
              autoComplete="off"
              size="small"
              />
          )}
          />
      </Grid>
      </Grid>
      </DialogContent>
      <DialogActions>
      <Button onClick={handleUpdateCategoryDialogClose}>
          {t('cancel')}
      </Button>
      <Button color="primary" type="submit">
          {t('update')}
      </Button>
      </DialogActions>
      </form>
  </Dialog>
  <Dialog open={dialogCreateOpen} onClose={handleCreateCategoryDialogClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '80%',            // Custom width
          maxWidth: '500px',       // Custom max-width
        },
      }}
    >
      <DialogTitle>{t('categories.create')}</DialogTitle>
      <form onSubmit={createHandleSubmit(onCreateCategorySubmit)}>
      <DialogContent>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 12, md: 12 }}>
      <Grid item xs={12}>
          <Controller
          name="name"
          control={createControl}
          render={({ field }) => (
              <TextField
              {...field}
              fullWidth
              id="name"
              label={t('name')}
              variant="filled"
              error={!!createErrors.name}
              helperText={createErrors.name?.message}
              autoComplete="off"
              size="small"
              />
          )}
          />
      </Grid>
      </Grid>
      </DialogContent>
      <DialogActions>
      <Button onClick={handleCreateCategoryDialogClose}>
          {t('cancel')}
      </Button>
      <Button color="primary" type="submit">
          {t('save')}
      </Button>
      </DialogActions>
      </form>
  </Dialog>
    <Box sx={{ height: 400, width: '100%' }}>
        <h4>{t('categories.list')}</h4>
       <Button variant="contained" sx={{mb:2}} onClick={handleCreateCategoryOpenDialog}><PersonAddIcon sx={{mr:1}}/>{t('addNew')}</Button>
       <Toolbar style={{padding:0}}>
        <TextField
          variant="outlined"
          label={t('search')}
          value={searchText}
          onChange={handleSearch}
          sx={{
            margin: 0, // Outer margin
            '& .MuiInputBase-root': {
              height: '40px', // Input height
              padding: '0 0px', // Input padding
              fontSize: '14px'
            },
            '& .MuiInputLabel-root': {
              padding: '0',
              marginTop: '-5px',
              fontSize: '14px',
            },
          }}
        />
      </Toolbar>
      <DataGrid
        rows={settingStore.categoryRowData}
        columns={
          [
            {
              field: "id",
              headerName: "#",
              filterable: false,
              width: 50,
              renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1
              },
              {field: 'name', headerName: t('name'), flex: 0.7},
              {
                  field: 'actions',
                  headerName: t('action'),
                  width: 100,
                  sortable: false,
                  filterable: false,
                  renderCell: (params) => (
                      <>
                          {authStore.role==='Admin' ? <Button style={{maxWidth:'40px', minWidth:'40px'}} onClick={()=>editData(params)}><EditIcon/></Button>:""}
                          {authStore.role==='Admin' ? <Button style={{maxWidth:'40px', minWidth:'40px'}} onClick={()=>deleteData(params)}><DeleteIcon/></Button>:""}
                      </>
                  )
              }
          ]
        }
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 25, 100]}
      />
    </Box>
    </>
  );
}

export default observer(ProductCategory);
