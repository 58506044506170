import { Button, Grid, TextField, dividerClasses, MenuItem, Box } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useStore } from '../../../store/rootStore'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import ServerSideAutocomplete from '../../../components/ui/ServerSideAutocomplete/ServerSideAutocomplete'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next';
import InputAdornment from '@mui/material/InputAdornment';



const UpdateItemForm:React.FC<any> = ({data, index, productUnits, productDetails, removeEdit}) => {
  const [units, setUnits] = useState<any[]>([]);
  const [unitId, setUnitId] = useState('');
  const [t, i18n] = useTranslation("global");

  useEffect(()=>{
    // console.log(productUnits);
    // console.log(productDetails.selected_unit);
    setUnits(productUnits);
    setUnitId(productDetails.selected_unit);
  },[]);
  
  const validationSchema = Yup.object().shape({
    product: Yup.object().shape({
      id: Yup.string().required(t('formValidation.required')),
      label: Yup.string().required(t('formValidation.required')),
    }).required(t('formValidation.required')),
    stock: Yup.number(),
      price: Yup.number(),
      quantity: Yup.number()
      .when('stock', (stock:any, schema) => 
        schema.max(stock, t('formValidation.outOfStock'))
      )
      .typeError(t('formValidation.numberOnly')).required(t('formValidation.required')).min(1, t('formValidation.minimumOne')),
    unit_id: Yup.string().required(t('formValidation.required')),
    discount: Yup.number().typeError(t('formValidation.numberOnly')).required(t('formValidation.required')).min(0, t('formValidation.minimumOne')).max(100, t('formValidation.maximumHundred')),
    total: Yup.number(),
    edit: Yup.number(),
    product_data: Yup.object(),
    itemIndex: Yup.number(),
    unitName: Yup.string(),
    defaultUnitName: Yup.string(),
    selected_unit: Yup.string(),
  })

  const { rootStore: { purchaseStore, productStore} } = useStore();

  const { control, handleSubmit, formState: { errors }, reset, setValue, getValues, clearErrors } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      product: data,
      stock:data.stock,
      price: data.price,
      quantity: productDetails.quantity,
      unit_id: data.default_unit,
      discount: productDetails.discount,
      total: productDetails.total,
      edit: 1,
      product_data: data,
      itemIndex: index,
      unitName:data.default_unit_name,
      defaultUnitName:data.default_unit_name,
      selected_unit:productDetails.selected_unit,
    }
  })

  const onSubmit = async (data:any) => {
    purchaseStore.addToCart(data);
    removeEdit();
    reset({
      product: {id: "", label: ""},
      stock:0,
      price: 0,
      quantity: 1,
      unit_id: "",
      discount: 0,
      total: 0,
      edit: 0,
      product_data: {},
      itemIndex: -1,
      unitName:"",
      defaultUnitName:"",
      selected_unit:"",
    })
  }


  const handleSelectProduct = (value: any) => {
    // console.log("handleSelectProduct ", value)
    // setValue('product', value); 
    // setValue('price', value?.price); 
    // setValue('total', value?.price); 
    // setValue('quantity', 1);
    // setValue('edit', 0);
    // setValue('product_data', value);
  };
  
  const handleUnit = (event:any) => {
    const selectedOption = event.target.value;
    setUnitId(selectedOption);  // Store the selected value
    setValue('selected_unit', selectedOption);
    const selectedText = units.find(option => option.id === selectedOption)?.name || '';
    // console.log(selectedText);
    setValue('unitName', selectedText);
    
    const u = units.filter((item)=>item.id===selectedOption);
    const calculate_value = u[0].calculate_value;
    const calculate_operator = u[0].operator;
    
    const original = getValues('price')?? 0
    const discount = getValues('discount') ?? 0
    const default_unit = getValues('unit_id')?? 0

    if(selectedOption == default_unit){
      const finalPrice = original - (original *  discount / 100);
      setValue('total', finalPrice*getValues('quantity'))
    }else if(calculate_operator == '*'){
        const finalPrice = (original*calculate_value) - (original *  discount / 100);
        setValue('total', finalPrice*getValues('quantity'))
    }else{
        const finalPrice = (original/calculate_value) - ((original/calculate_value) *  discount / 100);
        setValue('total', finalPrice*getValues('quantity'))
    }
    // console.log(calculate_value+calculate_operator);
  }

  const calculateFinalPrice = () => {
    // console.log(units);
    // console.log(unitId);
    const u = units.filter((item)=>item.id==unitId);
    // console.log(u);
    
    const calculate_value = u[0].calculate_value;
    const calculate_operator = u[0].operator;
    
    const default_unit = getValues('unit_id')?? 0
    const original = getValues('price')?? 0
    const discount = getValues('discount') ?? 0

    if(unitId == default_unit){
      const finalPrice = original - (original *  discount / 100);
      setValue('total', finalPrice*getValues('quantity'))
    }else if(calculate_operator == '*'){
        const finalPrice = (original*calculate_value) - (original *  discount / 100);
        setValue('total', finalPrice*getValues('quantity'))
    }else{
      const finalPrice = (original/calculate_value) - ((original/calculate_value) *  discount / 100);
        setValue('total', finalPrice*getValues('quantity'))
    }
    

    // if(calculate_operator == '*'){
    //   const finalPrice = (original*calculate_value) - (original *  discount / 100);
    //   setValue('total', finalPrice*getValues('quantity'))
    // }else if(calculate_operator == '/'){
    //   const finalPrice = (original/calculate_value) - (original *  discount / 100);
    //   setValue('total', finalPrice*getValues('quantity'))
    // }

    // const original = getValues('price')?? 0
    // const discount = getValues('discount') ?? 0
    // const finalPrice = original - (original *  discount / 100);
    // setValue('total', finalPrice*getValues('quantity'))
  }

  
  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
    <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3}} sx={{my:0}} alignItems="center" justifyContent="flex-end">
    <Grid item xs={12} sm={6} md={1.3} sx={{ fontSize: '17px' }}>
      {productDetails.product_name}
    </Grid>
    <Grid item xs={12} sm={6} md={1.2}>
      <Controller
          name="stock"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              onChange={(e) => {
                field.onChange(e);
                calculateFinalPrice()
              }}
              fullWidth
              id="stock"
              label={t('stock')}
              variant="filled"
              InputProps={{
                readOnly: true,
                disabled: true,
              }}
              sx={{
                margin: 0, // Outer margin
                '& .MuiInputBase-root': {
                  height: '40px', // Input height
                  padding: '0 0px', // Input padding
                  fontSize: '14px'
                },
                '& .MuiInputLabel-root': {
                  padding: '0',
                  marginTop: '-5px',
                  fontSize: '14px',
                },
              }}
              error={!!errors.stock}
              helperText={errors.stock?.message}
            />
          )}
        />
    </Grid>
    <Grid item xs={12} sm={7} md={1.8}>
      <Controller
          name="price"
          control={control}
          render={({ field }) => (
            <TextField
              sx={{
                margin: 0, // Outer margin
                '& .MuiInputBase-root': {
                  height: '40px', // Input height
                  padding: '0 14px', // Input padding
                  fontSize: '15px',
                },
                '& .MuiInputLabel-root': {
                  padding: '0',
                  marginTop: '-5px',
                  fontSize: '14px',
                },
              }}
              InputProps={{
                readOnly: true,
                disabled: true,
                startAdornment: (
                  <InputAdornment position="start">
                    Tk.
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Box
                      sx={{
                        position: 'absolute',
                        right: 10,
                        bottom: 1,
                        width: '100%',
                        textAlign: 'right',
                        fontSize: '13px'
                      }}
                    >
                      {getValues('defaultUnitName')}
                    </Box>
                  </InputAdornment>
                ),
              }}
              {...field}
              fullWidth
              id="price"
              label={t('price')}
              variant="filled"
              error={!!errors.price}
              helperText={errors.price?.message}
              
            />
          )}
        />
    </Grid>
    <Grid item xs={12} sm={6} md={1.3}>
      <Controller
          name="quantity"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              sx={{
                margin: 0, // Outer margin
                '& .MuiInputBase-root': {
                  height: '40px', // Input height
                  padding: '0 0px', // Input padding
                  textAlign: 'center',
                  fontSize: '15px',
                },
                '& .MuiInputLabel-root': {
                  padding: '0',
                  marginTop: '-5px',
                  fontSize: '14px',
                },
                '& .MuiFilledInput-input': {
                  textAlign: 'center',
                },
              }}
              onChange={(e) => {
                field.onChange(e);
                calculateFinalPrice()
              }}
              fullWidth
              id="quantity"
              label={t('quantity')}
              variant="filled"
              size="small"
              error={!!errors.quantity}
              helperText={errors.quantity?.message}
            />
          )}
        />
    </Grid>
    <Grid item xs={12} sm={6} md={1.4}>
        <Controller
        name="unit_id"
        control={control}
        render={({ field }) => (
            <TextField
            {...field}
            fullWidth
            select
            id="unit_id"
            value={unitId}
            label={t("unit")}
            variant="filled"
            error={!!errors.unit_id}
            helperText={errors.unit_id?.message}
            onChange={handleUnit}
            sx={{
              margin: 0, // Outer margin
              '& .MuiInputBase-root': {
                height: '40px', // Input height
                paddingleft: '8px', // Input padding
                paddingTop:'0px',
                fontSize: '12px',
              },
              '& .MuiInputLabel-root': {
                padding: '0',
                marginTop: '-5px',
                fontSize: '14px',
              },
            }}
            >
            {units?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                {option.name}
                </MenuItem>
            ))}
            </TextField>
        )}
        />
    </Grid>
    <Grid item xs={12} sm={6} md={1.2}>
      <Controller
          name="discount"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              onChange={(e) => {
                field.onChange(e);
                calculateFinalPrice()
              }}
              fullWidth
              id="discount"
              label={t('discount_per')}
              variant="filled"
              sx={{
                margin: 0, // Outer margin
                '& .MuiInputBase-root': {
                  height: '40px', // Input height
                  padding: '0 0px', // Input padding
                  fontSize: '14px'
                },
                '& .MuiInputLabel-root': {
                  padding: '0',
                  marginTop: '-5px',
                  fontSize: '14px',
                },
              }}
              error={!!errors.discount}
              helperText={errors.discount?.message}
            />
          )}
        />
    </Grid>
    <Grid item xs={12} sm={6} md={1.8}>
      <Controller
          name="total"
          control={control}
          render={({ field }) => (
            <TextField
              InputProps={{
                readOnly: true,
                disabled: true,
                startAdornment: (
                  <InputAdornment position="start">
                    Tk.
                  </InputAdornment>
                ),
              }}
              {...field}
              fullWidth
              id="total"
              label={t('total')}
              variant="filled"
              sx={{
                margin: 0, // Outer margin
                '& .MuiInputBase-root': {
                  height: '40px', // Input height
                  padding: '0 14px', // Input padding
                },
                '& .MuiInputLabel-root': {
                  padding: '0',
                  marginTop: '-5px',
                  fontSize: '14px',
                },
              }}
              error={!!errors.total}
              helperText={errors.total?.message}
            />
          )}
        />
    </Grid>
    <Grid item xs={1}>
        <Button sx={{ mt: 0 }} type="submit" variant="contained" color="secondary">
            {t('update')}
        </Button>
    </Grid>
  </Grid>
   </form>
  )
}

export default observer(UpdateItemForm)