import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useStore } from '../../../store/rootStore';
import { observer } from 'mobx-react-lite';
import LinearProgress from '@mui/material/LinearProgress';
import { Button, Grid, MenuItem, TextField, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


const Users = () => {

    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(false);
    const [t, i18n] = useTranslation("global");
    const [dialogCreateOpen, setDialogCreateOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [updateId, setUpdateId] = useState(null);

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const {rootStore: {settingStore, authStore}} = useStore();

    const createValidationSchema = Yup.object().shape({
        role: Yup.string().required('Category is required'),
        name: Yup.string().required(t('formValidation.name')),
        email: Yup.string().email(t('formValidation.InvalidEmail')).required(t('formValidation.required')),
        phone_number: Yup.string().required(t('formValidation.phone_number'))
          .min(11, t('formValidation.phone_len'))
          .max(11, t('formValidation.phone_len')),
        password: Yup.string()
          .min(8, t('formValidation.passwordLength'))
          .required(t('formValidation.required')),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password'), undefined], t('formValidation.nomatchPassword'))
          .required(t('formValidation.required')),
    })

    const { control:createControl, handleSubmit:createHandleSubmit, formState: { errors:createErrors }, reset:createReset, setValue:createSetValue, setError:createSetError } = useForm({
        resolver: yupResolver(createValidationSchema),
        defaultValues: {
          role: "",
          name: "",
          email: "",
          phone_number: "",
          password: "",
          confirmPassword: ""
        }
    })

    const updateValidationSchema = Yup.object().shape({
        id: Yup.string(),
        role: Yup.string().required('Category is required'),
        name: Yup.string().required(t('formValidation.name')),
        email: Yup.string().email(t('formValidation.InvalidEmail')).required(t('formValidation.required')),
        phone: Yup.string().required(t('formValidation.phone_number'))
          .min(11, t('formValidation.phone_len'))
          .max(11, t('formValidation.phone_len')),
    })

    const { control:updateControl, handleSubmit:updateHandleSubmit, formState: { errors:updateErrors }, reset:updateReset, setValue:updateSetValue, setError:updateSetError } = useForm({
        resolver: yupResolver(updateValidationSchema),
        defaultValues: {
            id: "",
            role: "",
            name: "",
            email: "",
            phone: "",
        }
    })

    const initTable = async () => {
        setLoading(true);
        try {
            const resData = await settingStore.fetchUserList()
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    useEffect(()=>{
        initTable();
    }, []);

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
    const handleMouseDownPassword = (event:any) => event.preventDefault();
    const handleMouseConfirmDownPassword = (event:any) => event.preventDefault();

    const onUpdateSubmit = async (data:any) => {
      setLoading(true);
      try {
          if(updateId !== null){
              const resData = await settingStore.updateUserData(data)
              // console.log(resData);
              updateReset({id:'',role:'',name:'', email:'',phone:''});
              handleUpdateDialogClose();
              setUpdateId(null);
          }
          setLoading(false);
          initTable();
      } catch (error:any) {
          Object.keys(error?.data).map((e:any) => {
          updateSetError(e, {
              type: 'manual', // Use 'manual' for manually triggered errors
              message: error?.data[e],
          });
          })
          setLoading(false);
      }
    }

    const onCreateSubmit = async (data:any) => {
      setLoading(true);
      try {
          const resData = await settingStore.createUserData(data)
          console.log(resData);
          createReset();
          handleCreateDialogClose();
          setLoading(false);
          initTable();
      } catch (error:any) {
          Object.keys(error?.data).map((e:any) => {
          createSetError(e, {
              type: 'manual', // Use 'manual' for manually triggered errors
              message: error?.data[e],
          });
          })
          setLoading(false);
      }
    }

    const handleUpdateDialogClose = () => {
        
        setDialogOpen(false);
    };

    const handleUpdateOpenDialog = () => {
        
        setDialogOpen(true);
    }

    const handleCreateDialogClose = () => {
        createReset();
        setDialogCreateOpen(false);
    };

    const handleCreateOpenDialog = () => {
        // createReset({name:'', calculate_value:0, operator:''});
        setDialogCreateOpen(true);
    }
    
    const roles = [
      {id:0, name:'Admin'},
      {id:1, name:'Manager'},
      {id:2, name:'Salesman'}
    ]
    
    const confirmDelete = async(id:any)=>{
      setLoading(true);
      try {
        const resData = await settingStore.deleteUserData(id);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }

    const deleteData = (params:any) => {

      settingStore.rootStore.dialogStore.openDialog({
        confirmFn: () => confirmDelete(params.id),
        dialogText: t("users.deleteConfirmText")
      })
      // customerStore.deleteDialog(params);
      // setLoading(false);
    }
    

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.toLowerCase();
      setSearchText(event.target.value);

      settingStore.userRowData = settingStore.searchUserData.filter((row)=>{
        return row.name.toLowerCase().includes(value)
          || row.email.toLowerCase().includes(value)
          || row.phone.toLowerCase().includes(value)
          || row.role.toLowerCase().includes(value);
      })
    };
    
    const editData = async(params:any) => {
      updateReset({id:'',role:'',name:'', email:'',phone:''});
      setUpdateId(params.id);
      handleUpdateOpenDialog();
      setLoading(true);
      try {
          
          const resData = await settingStore.getUserData(params.id)
          updateReset(resData.data.user)
          updateSetValue('id', params.id);
          setLoading(false);
      } catch (error) {
          // Handle errors, e.g., show an error message
          setLoading(false);
          console.error('Error while fetching data:', error);
      }

      // console.log(params.id);

    }

  return (
    <>
    {loading ? 
    <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
        <LinearProgress color="secondary"/>
    </Box>
    : ''
    }
    <Dialog open={dialogOpen} onClose={handleUpdateDialogClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '80%',            // Custom width
          maxWidth: '500px',       // Custom max-width
        },
      }}
    >
      <DialogTitle>{t('users.update')}</DialogTitle>
      <form onSubmit={updateHandleSubmit(onUpdateSubmit)}>
      <DialogContent>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 12, md: 12 }}>
      <Grid item xs={12}>
          <Controller
          name="role"
          control={updateControl}
          render={({ field }) => (
              <TextField
              {...field}
              fullWidth
              select
              id="category_id"
              label={t("role")}
              variant="filled"
              error={!!updateErrors.role}
              helperText={updateErrors.role?.message}
              sx={{
                  margin: 0, // Outer margin
                  '& .MuiInputBase-root': {
                    height: '40px', // Input height
                    paddingleft: '8px', // Input padding
                    paddingTop:'0px',
                    fontSize: '12px',
                  },
                  '& .MuiInputLabel-root': {
                    padding: '0',
                    marginTop: '-5px',
                    fontSize: '14px',
                  },
                }}
              >
              {roles.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                  {option.name}
                  </MenuItem>
              ))}
              </TextField>
          )}
          />
      </Grid>
      <Grid item xs={12}>
          <Controller
          name="name"
          control={updateControl}
          render={({ field }) => (
              <TextField
              {...field}
              fullWidth
              id="name"
              label={t('name')}
              variant="filled"
              error={!!updateErrors.name}
              helperText={updateErrors.name?.message}
              autoComplete="off"
              sx={{
                margin: 0, // Outer margin
                '& .MuiInputBase-root': {
                  height: '40px', // Input height
                  padding: '0 0px', // Input padding
                  fontSize: '14px'
                },
                '& .MuiInputLabel-root': {
                  padding: '0',
                  marginTop: '-5px',
                  fontSize: '14px',
                },
              }}
              />
          )}
          />
      </Grid>
      <Grid item xs={12}>
          <Controller
          name="email"
          control={updateControl}
          render={({ field }) => (
              <TextField
              {...field}
              fullWidth
              id="email"
              label={t('email')}
              variant="filled"
              error={!!updateErrors.email}
              helperText={updateErrors.email?.message}
              autoComplete="off"
              sx={{
                margin: 0, // Outer margin
                '& .MuiInputBase-root': {
                  height: '40px', // Input height
                  padding: '0 0px', // Input padding
                  fontSize: '14px'
                },
                '& .MuiInputLabel-root': {
                  padding: '0',
                  marginTop: '-5px',
                  fontSize: '14px',
                },
              }}
              />
          )}
          />
      </Grid>
      <Grid item xs={12}>
          <Controller
          name="phone"
          control={updateControl}
          render={({ field }) => (
              <TextField
              {...field}
              fullWidth
              id="phone_number"
              label={t('phone')}
              variant="filled"
              error={!!updateErrors.phone}
              helperText={updateErrors.phone?.message}
              autoComplete="off"
              sx={{
                margin: 0, // Outer margin
                '& .MuiInputBase-root': {
                  height: '40px', // Input height
                  padding: '0 0px', // Input padding
                  fontSize: '14px'
                },
                '& .MuiInputLabel-root': {
                  padding: '0',
                  marginTop: '-5px',
                  fontSize: '14px',
                },
              }}
              />
          )}
          />
      </Grid>
      </Grid>
      </DialogContent>
      <DialogActions>
      <Button onClick={handleUpdateDialogClose}>
          {t('cancel')}
      </Button>
      <Button color="primary" type="submit">
          {t('update')}
      </Button>
      </DialogActions>
      </form>
  </Dialog>
  <Dialog open={dialogCreateOpen} onClose={handleCreateDialogClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '80%',            // Custom width
          maxWidth: '500px',       // Custom max-width
        },
      }}
    >
      <DialogTitle>{t('users.create')}</DialogTitle>
      <form onSubmit={createHandleSubmit(onCreateSubmit)}>
      <DialogContent>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 12, md: 12 }}>
      <Grid item xs={12}>
          <Controller
          name="role"
          control={createControl}
          render={({ field }) => (
              <TextField
              {...field}
              fullWidth
              select
              id="category_id"
              label={t("role")}
              variant="filled"
              error={!!createErrors.role}
              helperText={createErrors.role?.message}
              sx={{
                  margin: 0, // Outer margin
                  '& .MuiInputBase-root': {
                    height: '40px', // Input height
                    paddingleft: '8px', // Input padding
                    paddingTop:'0px',
                    fontSize: '12px',
                  },
                  '& .MuiInputLabel-root': {
                    padding: '0',
                    marginTop: '-5px',
                    fontSize: '14px',
                  },
                }}
              >
              {roles.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                  {option.name}
                  </MenuItem>
              ))}
              </TextField>
          )}
          />
      </Grid>
      <Grid item xs={12}>
          <Controller
          name="name"
          control={createControl}
          render={({ field }) => (
              <TextField
              {...field}
              fullWidth
              id="name"
              label={t('name')}
              variant="filled"
              error={!!createErrors.name}
              helperText={createErrors.name?.message}
              autoComplete="off"
              sx={{
                margin: 0, // Outer margin
                '& .MuiInputBase-root': {
                  height: '40px', // Input height
                  padding: '0 0px', // Input padding
                  fontSize: '14px'
                },
                '& .MuiInputLabel-root': {
                  padding: '0',
                  marginTop: '-5px',
                  fontSize: '14px',
                },
              }}
              />
          )}
          />
      </Grid>
      <Grid item xs={12}>
          <Controller
          name="email"
          control={createControl}
          render={({ field }) => (
              <TextField
              {...field}
              fullWidth
              id="email"
              label={t('email')}
              variant="filled"
              error={!!createErrors.email}
              helperText={createErrors.email?.message}
              autoComplete="off"
              sx={{
                margin: 0, // Outer margin
                '& .MuiInputBase-root': {
                  height: '40px', // Input height
                  padding: '0 0px', // Input padding
                  fontSize: '14px'
                },
                '& .MuiInputLabel-root': {
                  padding: '0',
                  marginTop: '-5px',
                  fontSize: '14px',
                },
              }}
              />
          )}
          />
      </Grid>
      <Grid item xs={12}>
          <Controller
          name="phone_number"
          control={createControl}
          render={({ field }) => (
              <TextField
              {...field}
              fullWidth
              id="phone_number"
              label={t('phone')}
              variant="filled"
              error={!!createErrors.phone_number}
              helperText={createErrors.phone_number?.message}
              autoComplete="off"
              sx={{
                margin: 0, // Outer margin
                '& .MuiInputBase-root': {
                  height: '40px', // Input height
                  padding: '0 0px', // Input padding
                  fontSize: '14px'
                },
                '& .MuiInputLabel-root': {
                  padding: '0',
                  marginTop: '-5px',
                  fontSize: '14px',
                },
              }}
              />
          )}
          />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="password"
          control={createControl}
          render={({ field }) => (
              <TextField
              {...field}
              fullWidth
              type={showPassword ? 'text' : 'password'}
              id="password"
              label={t('password')}
              variant="filled"
              error={!!createErrors.password}
              helperText={createErrors.password?.message}
              autoComplete="off"
              sx={{
                margin: 0, // Outer margin
                '& .MuiInputBase-root': {
                  height: '40px', // Input height
                  padding: '0 0px', // Input padding
                  fontSize: '14px'
                },
                '& .MuiInputLabel-root': {
                  padding: '0',
                  marginTop: '-5px',
                  fontSize: '14px',
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end"
                  >
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      sx={{ marginRight: '3px' }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              />
          )}
          />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="confirmPassword"
          control={createControl}
          render={({ field }) => (
              <TextField
              {...field}
              fullWidth
              type={showConfirmPassword ? 'text' : 'password'}
              id="confirmPassword"
              label={t('confirmPassword')}
              variant="filled"
              error={!!createErrors.confirmPassword}
              helperText={createErrors.confirmPassword?.message}
              autoComplete="off"
              sx={{
                margin: 0, // Outer margin
                '& .MuiInputBase-root': {
                  height: '40px', // Input height
                  padding: '0 0px', // Input padding
                  fontSize: '14px'
                },
                '& .MuiInputLabel-root': {
                  padding: '0',
                  marginTop: '-5px',
                  fontSize: '14px',
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end"
                  >
                    <IconButton
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseConfirmDownPassword}
                      edge="end"
                      sx={{ marginRight: '3px' }}
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              />
          )}
          />
      </Grid>
      </Grid>
      </DialogContent>
      <DialogActions>
      <Button onClick={handleCreateDialogClose}>
          {t('cancel')}
      </Button>
      <Button color="primary" type="submit">
          {t('save')}
      </Button>
      </DialogActions>
      </form>
  </Dialog>
    <Box sx={{ height: 400, width: '100%' }}>
        <h4>{t('users.list')}</h4>
       <Button variant="contained" sx={{mb:2}} onClick={handleCreateOpenDialog}><PersonAddIcon sx={{mr:1}}/>{t('addNew')}</Button>
       <Toolbar style={{padding:0}}>
        <TextField
          variant="outlined"
          label={t('search')}
          value={searchText}
          onChange={handleSearch}
          sx={{
            margin: 0, // Outer margin
            '& .MuiInputBase-root': {
              height: '40px', // Input height
              padding: '0 0px', // Input padding
              fontSize: '14px'
            },
            '& .MuiInputLabel-root': {
              padding: '0',
              marginTop: '-5px',
              fontSize: '14px',
            },
          }}
        />
      </Toolbar>
      <DataGrid
        rows={settingStore.userRowData}
        columns={
          [
            {
              field: "id",
              headerName: "#",
              filterable: false,
              width: 50,
              renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1
              },
              {field: 'name', headerName: t('name'), flex: 0.2},
              {field: 'email', headerName: t('email'), flex: 0.2},
              {field: 'phone', headerName: t('phone'), flex: 0.2},
              {field: 'role', headerName: t('role'), flex: 0.2},
              {
                  field: 'actions',
                  headerName: t('action'),
                  width: 100,
                  sortable: false,
                  filterable: false,
                  renderCell: (params) => (
                      <>
                          {authStore.role==='Admin' ? <Button style={{maxWidth:'40px', minWidth:'40px'}} onClick={()=>editData(params)}><EditIcon/></Button>:""}
                          {authStore.role==='Admin' ? <Button style={{maxWidth:'40px', minWidth:'40px'}} onClick={()=>deleteData(params)}><DeleteIcon/></Button>:""}
                      </>
                  )
              }
          ]
        }
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 25, 100]}
      />
    </Box>
    </>
  );
}

export default observer(Users);
