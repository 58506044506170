import { 
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    Avatar,
    CssBaseline,
    FormControlLabel,
    Checkbox,
    Link,
    Typography,
    Container,
    createTheme,
    ThemeProvider
    } from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React from "react";
import * as yup from "yup";
import { Navigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStore } from "../../store/rootStore";
import { observer } from "mobx-react-lite";
import LinearProgress from '@mui/material/LinearProgress';
import AppAlert from "../../components/alert/AppAlert";



const defaultTheme = createTheme();

const schema = yup.object().shape({
    email: yup.string().required('ইমেইল ফিল্ড টি পূরন করতে হবে').email('এটা সঠিক ইমেইল নয়'),
    password: yup.string().required('পাসওয়ার্ড ফিল্ড টি পূরন করতে হবে').min(8, 'কমপক্ষে ৮ সংখ্যার পাসওয়ার্ড দিতে হবে'),

});

const Login = () => {

    const {
        handleSubmit,
        register,
        control,
        formState: {errors, isSubmitting},
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            email: "",
            password: ""
        }
    });
    const [loading, setLoading] = React.useState(false);
    const { rootStore: { authStore } } = useStore();

    const isAuthenticated = authStore.isAuthenticated;
    if(isAuthenticated){
        return <Navigate to="/app/dashboard" />;
    }

    const onSubmit = async (data: any) => {
        setLoading(true);
        try {
            const resData = await authStore.login({
                email: data.email,
                password: data.password
            })
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    return (
        <>
            {loading ? 
            <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%' }}>
                <LinearProgress color="secondary"/>
            </Box>
            : ''
            }
            <ThemeProvider theme={defaultTheme}>
                <Container maxWidth="xs">
                    <CssBaseline />
                    <Box
                    sx={{
                        marginTop: '40px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    >
                        <AppAlert/>
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography sx={{fontFamily:"BenSen", fontSize:"25pt"}} component="h1" variant="h5">
                            সাইন ইন
                        </Typography>
                        <Box>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                        margin="normal"
                                        fullWidth
                                        id="email"
                                        label="ইমেইল"
                                        autoComplete="email"
                                        error={!!errors.email}
                                        helperText={errors.email ? errors.email.message : ''}
                                        {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="password"
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                        margin="normal"
                                        fullWidth
                                        label="পাসওয়ার্ড"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                        error={!!errors.password}
                                        helperText={errors.password ? errors.password.message : ''}
                                        {...field}
                                        />
                                    )}
                                />
                                <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="আমাকে মনে রাখুন"
                                />
                                <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={isSubmitting}
                                >
                                সাইন ইন
                                </Button>
                                <Grid container>
                                    <Grid item xs>
                                        <Link href="#">
                                        পাসওয়ার্ড ভূলে গেছেন?
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link href="#">
                                        {"একাউন্ট খোলা না থাকলে? একাউন্ট তৈরি করুন"}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </>
    )
}

export default observer(Login);