import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useStore } from '../../../store/rootStore';
import { observer } from 'mobx-react-lite';
import LinearProgress from '@mui/material/LinearProgress';
import { Button, TextField, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const CustomerList = () => {

    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(false);
    const [t, i18n] = useTranslation("global");

    const {rootStore: {customerStore}} = useStore();

    const initTable = async () => {
        setLoading(true);
        try {
            const resData = await customerStore.fetchList();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    useEffect(()=>{
        initTable();
    }, []);

    const confirmDelete = async(id:any)=>{
      setLoading(true);
      try {
        const resData = await customerStore.deleteData(id);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }

    const deleteData = (params:any) => {

      customerStore.rootStore.dialogStore.openDialog({
        confirmFn: () => confirmDelete(params.id),
        dialogText: t("customers.deleteConfirmText")
      })
      // customerStore.deleteDialog(params);
      // setLoading(false);
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.toLowerCase();
      setSearchText(event.target.value);

      customerStore.rowData = customerStore.searchData.filter((row)=>{
        return row.name.toLowerCase().includes(value)
         || row.phone_number.toLowerCase().includes(value)
         || row.address.toLowerCase().includes(value);
      })
    };

  return (
    <>
    {loading ? 
    <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
        <LinearProgress color="secondary"/>
    </Box>
    : ''
    }
    <Box sx={{ height: 400, width: '100%' }}>
       <Button variant="contained" sx={{mb:2}} component={Link} to="create"><PersonAddIcon sx={{mr:1}}/>{t('addNew')}</Button>
       <Toolbar style={{padding:0}}>
        <TextField
          variant="outlined"
          label={t('search')}
          value={searchText}
          onChange={handleSearch}
          sx={{
            margin: 0, // Outer margin
            '& .MuiInputBase-root': {
              height: '40px', // Input height
              padding: '0 0px', // Input padding
              fontSize: '14px'
            },
            '& .MuiInputLabel-root': {
              padding: '0',
              marginTop: '-5px',
              fontSize: '14px',
            },
          }}
        />
      </Toolbar>
      <DataGrid
        rows={customerStore.rowData}
        columns={
          [
            {
              field: "id",
              headerName: "#",
              filterable: false,
              width: 50,
              renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1
              },
              {field: 'name', headerName: t('name'), width: 220},
              {field: 'phone_number', headerName: t('phone'), width: 150},
              {field: 'address', headerName: t('address'), width: 300},
              {
                  field: 'actions',
                  headerName: t('action'),
                  width: 100,
                  sortable: false,
                  filterable: false,
                  renderCell: (params) => (
                      <>
                          <Button style={{maxWidth:'40px', minWidth:'40px'}} component={Link} to={'edit/'+params.id}><EditIcon/></Button>
                          <Button style={{maxWidth:'40px', minWidth:'40px'}} onClick={()=>deleteData(params)}><DeleteIcon/></Button>
                      </>
                  )
              }
          ]
        }
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 25, 100]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
    </>
  );
}

export default observer(CustomerList);
