import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';


interface ServerSideSupplierAutocompleteProps {
  label: string;
  ajaxCallFn: Function;
  addNewFn: Function;
  onOptionSelect: (option: any) => void;
  error: any;
  field: any; 
}

const ServerSideSupplierAutocomplete: React.FC<ServerSideSupplierAutocompleteProps> = ({
  label,
  ajaxCallFn,
  addNewFn,
  onOptionSelect,
  error,
  field
}) => {
  const [options, setOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [t, i18n] = useTranslation("global");
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchOptions();
        setOptions(response);
       
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };
    fetchData();
  }, [inputValue, onOptionSelect, error]);

  const fetchOptions = async () => {
    setLoading(true);
    const response = await ajaxCallFn({search: inputValue});
    setLoading(false);
    return response;
  };

  const handleKeyUp = (e:any) => {
    setInputValue(e.target.value);
  };

  const handelAddNew = () => {
    addNewFn();
  }

  return (
    <Autocomplete
    {...field}
    options={options || []}
    getOptionLabel={(option:any) => {
      if(option.label){
        if(option.isNew){
          return t('noSupplierWithNew');
        }else{
          return `${option.label}-${option.phone_number}-${option.address}`;
        }
      }else{
        return "";
      }
    }}
    isOptionEqualToValue={(option:any, value:any) => option.label === value.label}
    filterOptions={(x) => x}
    onKeyUp={(e:any) => { setInputValue(e.target.value)}}
    onChange={(_, newValue) => { onOptionSelect(newValue)}}
    autoHighlight
    renderInput={(params) => (
      <TextField 
      {...params}
      label={label} 
      variant="filled" 
      fullWidth  
      error={!!error}
      helperText={error?.message}
      autoComplete="new-password"
      autoFocus={false}
      sx={{
        margin: 0, // Outer margin
        '& .MuiInputBase-root': {
          height: '40px', // Input height
          paddingleft: '8px', // Input padding
          paddingTop:'8px',
          fontSize: '15px',
        },
        '& .MuiInputLabel-root': {
          padding: '0',
          marginTop: '-5px',
          fontSize: '14px',
        },
      }}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
              <Button
                sx={{
                  padding: 0,          // removes padding
                  margin: '-8px -5px 0px 0px',           // removes margin
                  minWidth: 0,         // removes default width
                  width: 'auto',       // or set a specific width if needed
                }}
                onClick={handelAddNew}
              ><AddIcon fontSize="small"/></Button>
            </>
          ),
        }}
        />
      )}
    />
  );
};

export default observer(ServerSideSupplierAutocomplete);