import { action, makeObservable, observable } from "mobx";
import { IRootStore } from "./rootStore";

export default class AuthStore {

    BASE_URL = process.env.REACT_APP_API_URL + '/auth';
    isAuthenticated: boolean = false;
    userId: string|null = null;
    token: string|null = null;
    role: string|null = null;
    rootStore: IRootStore;

    constructor(rootStore: IRootStore) {
        makeObservable(this, {
            isAuthenticated: observable,
            userId: observable,
            token: observable,
            role: observable,
            setIsAuthenticated: action,
            setToken: action,
            setUserId: action,
            login: action,
            logout: action
        });
        this.rootStore = rootStore;
        this.setToken(localStorage.getItem('_moddhobittobdtoken'));
        if(this.token) this.isAuthenticated = true;
        this.setUserRole(localStorage.getItem('_moddhobittobduserrole'));
        this.setUserId(localStorage.getItem('_moddhobittobduserid'));
    }

    setIsAuthenticated = (value: boolean) => {
        this.isAuthenticated = value;
        if(!value){
            this.setToken(null)
            this.setUserId(null);
            this.setUserRole(null);
        };
    }

    setToken = (value: string|null) => {
        if(value){
            localStorage.setItem("_moddhobittobdtoken", value);
        }else{
            localStorage.removeItem("_moddhobittobdtoken");
            localStorage.removeItem("_moddhobittobduser");
        }

        this.token = value;
    }

    setUserId = (value:string|null) => {
        if(value){
            localStorage.setItem("_moddhobittobduserid", value);
        }else{
            localStorage.removeItem("_moddhobittobduserid");
        }

        this.userId = value;
    }

    setUserRole = (value:string|null) => {
        if(value){
            localStorage.setItem("_moddhobittobduserrole", value);
        }else{
            localStorage.removeItem("_moddhobittobduserrole");
        }

        this.role = value;
    }
    
    login = async (postData: any) => {
        try {
            
            const response = await fetch(this.BASE_URL + '/login', {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postData)
            })

            const data = await response.json();
            if(data.error){
                this.rootStore.handleError(response.status, data.message, data);
                return Promise.reject(data);
            }else{
                await this.getData(data.access_token);

                this.setIsAuthenticated(true);
                this.setToken(data.access_token);
                console.log("logged in");
                
                return Promise.resolve(data);
            }

        } catch (error: any) {
            this.rootStore.handleError(419, "Something goes wrong", error);
        }
    }

    // View
    getData = async (token: string) => {
        try {
        
        const response = await fetch(this.BASE_URL + `/me`, {
            method: 'GET',
            headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', // You can adjust this header as needed
            }
        })
        const data = await response.json();
        if (data.error) {
            this.rootStore.handleError(response.status, data.message, data);
            return Promise.reject(data)
        } else {
            console.log(data);
            this.setUserId(data.id);
            this.setUserRole(data.role);
            return Promise.resolve(data)
        }
        
        } catch (error: any) {
        this.rootStore.handleError(419, "Something went wrong!", error)
        }
    }

    logout = async () => {
        try {
            const response = await fetch(this.BASE_URL + '/logout', {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${this.token}`,
                    'Content-Type': 'application/json'
                }
            })

            const data = await response.json();
            if(data.error){
                this.rootStore.handleError(response.status, data.message, data);
                return Promise.reject(data);
            }else{
                this.setIsAuthenticated(false);
                this.rootStore.alertStore.open({status: "success", message: data.message})
                return Promise.resolve(data);
            }

        } catch (error) {
            this.rootStore.handleError(419, "Something goes wrong", error);
        }
    }

}