import React from "react";
import { useStore } from "../store/rootStore";
import { Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

const PrivateRoute: React.FC<{
    element: JSX.Element;
    roles: any[];
}> = ({element, roles}) => {

    const { rootStore: { authStore } } = useStore();
    const isAuthenticated = authStore.isAuthenticated;
    if(!isAuthenticated){
        return <Navigate to={"/login"}/>
    }

    if (roles && !roles.includes(authStore.role)) {
        return <Navigate to="/unauthorized" />;
    }
    return element;
}

export default observer(PrivateRoute);