import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import ViewInvoice from "./viewInvoice";


const SaleView = () =>{
  
  const { id } = useParams()

  return(
    <ViewInvoice id={id}/>
  )
}

export default observer(SaleView);
