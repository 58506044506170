import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import { Outlet } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Settings = () => {
    const [t, i18n] = useTranslation("global");
    return (
        <>
            <h2>{t('settings.title')}</h2>
            <Box sx={{mt:2}}>
                <Outlet/>
            </Box>
        </>
    )
}

export default observer(Settings);