import React, { useEffect } from "react";
import { Box, Collapse, Alert, Snackbar, IconButton, Button, AlertTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useStore } from "../../store/rootStore";
import { observer } from 'mobx-react-lite';

const AppAlert = () => {
    const {rootStore: {alertStore} } = useStore();
    const {isAletOpen, alertData, close} = alertStore;

    return (
        <Box sx={{ mt:3, width: '100%' }}>
        <Snackbar
            open={isAletOpen}
            autoHideDuration={4000} // Auto-hide after 4 second
            onClose={close}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Position of the alert
        >
        <Alert
        action={
            <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
                close();
            }}
            >
            <CloseIcon fontSize="inherit" />
            </IconButton>
        }
        sx={{ mb: 2 }}
        severity={alertData?.status}
        >
            <AlertTitle>{alertData?.status.toUpperCase() ?? 'ERROR'}</AlertTitle>
            {alertData?.message}
        </Alert>
        </Snackbar>
        </Box>
    );
}

export default observer(AppAlert)