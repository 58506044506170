// ProfilePage.js
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../store/rootStore';
import LinearProgress from '@mui/material/LinearProgress';
import { Avatar, Box, Button, Container, Grid, Paper, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';

interface Profile {
    id: number;
    role: string;
    name: string;
    email: string;
    phone: string;
    photo: string;
}

const ProfilePage = () => {
  
    const [loading, setLoading] = useState(false);
    const {rootStore: {authStore, settingStore}} = useStore();
    const token = authStore.token ?? '';
    const [user, setUser] = useState<Profile | null>(null);
    const [imageUrl, setimage] = useState(null);

    const navigate = useNavigate();

    const initTable = async () => {
        setLoading(true);
        try {
            const resData = await settingStore.getProfileData(token);
            console.log(resData);
            setUser(resData);
            setimage(resData.photo);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    const handleEditClick = () => {
        navigate('/app/profile/edit'); // Redirect to update profile page
      };

    function getLastUrlParam(url:any) {
        const params = url?.split('/').pop() || "null";
        const lastParam = params[params.length - 1]; // Get the last element
        return lastParam;
    }

    useEffect(()=>{
        initTable();
    
    }, []);

    return (
        <>
        {loading ? 
        <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
            <LinearProgress color="secondary"/>
        </Box>
        : ''
        }
        <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
            <Paper elevation={3} style={{ padding: '2rem', textAlign: 'center' }}>
                <Avatar 
                src={process.env.REACT_APP_STORAGE_URL+"/"+imageUrl} 
                alt={""} 
                sx={{ width: 100, height: 100, margin: 'auto', mb: 2 }} 
                />
                <Typography variant="h5">{user !== null ? user.role:""}</Typography>
                <Typography variant="h5">{user !== null ? user.name:""}</Typography>
                <Typography variant="body1">{user !== null ? user.email:""}</Typography>
                <Typography variant="body2" color="textSecondary">{user !== null ? user.phone:""}</Typography>
                <Grid container justifyContent="center" mt={2}>
                <Button 
                    disabled={user !== null ? false:true}
                    variant="contained" 
                    startIcon={<EditIcon />} 
                    onClick={handleEditClick}
                >
                    Edit Profile
                </Button>
                </Grid>
            </Paper>
            </Container>
        </>
    );
};

export default observer(ProfilePage);
