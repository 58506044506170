// NotFoundPage.tsx

import { observer } from 'mobx-react-lite';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
    const nagivate = useNavigate();
    const handleRedirect = () => {
        nagivate('..');
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.title}>404 - Page Not Found</h1>
            <p style={styles.message}>
                Sorry, the page you are looking for does not exist.
            </p>
            <button style={styles.button} onClick={handleRedirect}>
                Go to Home
            </button>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex' as const,
        flexDirection: 'column' as const,
        alignItems: 'center as const',
        justifyContent: 'center' as const,
        height: '100vh' as const,
        backgroundColor: '#f8d7da' as const,
        color: '#721c24' as const,
        textAlign: 'center' as const,
        padding: '20px' as const,
    },
    title: {
        fontSize: '2rem',
        margin: '0',
    },
    message: {
        margin: '20px 0',
    },
    button: {
        padding: '10px 20px',
        fontSize: '1rem',
        cursor: 'pointer',
        backgroundColor: '#f5c6cb',
        border: 'none',
        borderRadius: '5px',
        color: '#721c24',
    },
};

export default observer(NotFoundPage);
