import React from "react";
import { action, makeObservable, observable } from "mobx";
import { IRootStore } from "./rootStore";
import { GridColDef, GridRowsProp, GridValidRowModel } from "@mui/x-data-grid";
import { Button, ListItem, ListItemButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Define your row type

interface DailyCustomerReportRowModel extends GridValidRowModel {
    name: string;
    phone: string;
    address: string;
    salesAount: string;
    cashPayment: string;
    duePayment: string;
    advancePayment: string;
    date: string;
}

interface DailySupplierReportRowModel extends GridValidRowModel {
    name: string;
    company: string;
    phone: string;
    address: string;
    purchasesAount: string;
    cashPayment: string;
    duePayment: string;
    advancePayment: string;
    date: string;
}

interface CustomerDueReportRowModel extends GridValidRowModel {
    name: string;
    phone_number: string;
    address: string;
    purchase: string;
    payment: string;
    due_payment: string;
    advance_payment: string;
    totalDue: string;
    lastPaymentDate: string;
}

export default class ReportStore {
    
    BASE_URL = process.env.REACT_APP_API_URL + '/v1/reports';
    
    rootStore: IRootStore;
    customerDailyReportRowData: DailyCustomerReportRowModel[] = [];
    searchCustomerDailyReportData: DailyCustomerReportRowModel[] = [];
    supplierDailyReportRowData: DailySupplierReportRowModel[] = [];
    searchSupplierDailyReportData: DailySupplierReportRowModel[] = [];
    customerDueReportRowData: CustomerDueReportRowModel[] = [];
    searchCustomerDueReportData: CustomerDueReportRowModel[] = [];
    columns: GridColDef[] = [
        // {field: 'id', headerName: 'Id', width: 100},
        
    ];

    constructor(rootStore: IRootStore) {
        makeObservable(this, {
            customerDailyReportRowData: observable,
            searchCustomerDailyReportData: observable,
            supplierDailyReportRowData: observable,
            searchSupplierDailyReportData: observable,
            customerDueReportRowData: observable,
            searchCustomerDueReportData: observable,
            columns: observable,
            setDailyCustomerReportRowData: action,
            setDailySupplierReportRowData: action,
            setCustomerDueReportRowData: action,
            fetchDailyCustomerReportList: action,
            fetchDailySupplierReportList: action,
            fetchCustomerDueReportList: action,
            updateDueDate: action,
        });
        this.rootStore = rootStore;
    }

    setDailyCustomerReportRowData(values: DailyCustomerReportRowModel[]){
        this.customerDailyReportRowData = values;
        this.searchCustomerDailyReportData = values;
    }

    setDailySupplierReportRowData(values: DailySupplierReportRowModel[]){
        this.supplierDailyReportRowData = values;
        this.searchSupplierDailyReportData = values;
    }

    setCustomerDueReportRowData(values: CustomerDueReportRowModel[]){
        this.customerDueReportRowData = values;
        this.searchCustomerDueReportData = values;
    }

    // Show
    fetchDailyCustomerReportList = async (date:string) => {
        try {
            
            const response = await fetch(this.BASE_URL + `/dailyCustomerReports/${date}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                    'Content-Type': 'application/json'
                }
            })

            const data = await response.json();
            if (data.error) {
                this.rootStore.handleError(response.status, `HTTP Request failed: ${response.status} ${response.statusText}`, data);
                return Promise.reject(data)
            } else {
                this.setDailyCustomerReportRowData(data.data);
                return Promise.resolve(data)
            }

        } catch (error) {
            this.rootStore.handleError(419, "Something went wrong!", error);
        }
    }

    // Show
    fetchDailySupplierReportList = async (date:string) => {
        try {
            
            const response = await fetch(this.BASE_URL + `/dailySupplierReports/${date}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                    'Content-Type': 'application/json'
                }
            })

            const data = await response.json();
            if (data.error) {
                this.rootStore.handleError(response.status, `HTTP Request failed: ${response.status} ${response.statusText}`, data);
                return Promise.reject(data)
            } else {
                this.setDailySupplierReportRowData(data.data);
                return Promise.resolve(data)
            }

        } catch (error) {
            this.rootStore.handleError(419, "Something went wrong!", error);
        }
    }
    
    // Show
    fetchCustomerDueReportList = async (date: string) => {
        try {
            
            const response = await fetch(this.BASE_URL + `/customerDueReports/${date}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                    'Content-Type': 'application/json'
                }
            })

            const data = await response.json();
            if (data.error) {
                this.rootStore.handleError(response.status, `HTTP Request failed: ${response.status} ${response.statusText}`, data);
                return Promise.reject(data)
            } else {
                this.setCustomerDueReportRowData(data.data.customers);
                return Promise.resolve(data)
            }

        } catch (error) {
            this.rootStore.handleError(419, "Something went wrong!", error);
        }
    }

    // Update
    updateDueDate = async (postData: any) => {
        try {
        const response = await fetch(this.BASE_URL + `/updateDueDate`, {
            method: 'POST',
            headers: {
            'Authorization': `Bearer ${this.rootStore.authStore.token}`,
            'Content-Type': 'application/json', // You can adjust this header as needed
            },
            body: JSON.stringify(postData)
        })

        const data = await response.json();
        if (data.error) {
            this.rootStore.handleError(response.status,  data.message, data);
            return Promise.reject(data)
        } else {
            this.rootStore.alertStore.open({status: "success", message: data.message})
            return Promise.resolve(data)
        }
        } catch (error: any) {
        this.rootStore.handleError(419, "Something went wrong!", error)
        }
    }
}