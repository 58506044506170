import { observer } from "mobx-react-lite";
import React, {useState, useEffect} from "react";
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, TextField, FormControlLabel, Checkbox, Button, CardMedia, Card, MenuItem } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom'
import { useStore } from '../../../store/rootStore';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';
import ServerSideSupplierAutocomplete from "../../../components/ui/ServerSideAutocomplete/ServerSideSupplierAutocomplete";
import AddNewItemForm from "./addNewItemForm";
import AllItemsList from "./allItemsList";
import InputAdornment from '@mui/material/InputAdornment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const PurchaseCreate = () => {

    const { rootStore: { purchaseStore, supplierStore } } = useStore();
    const [t, i18n] = useTranslation("global");
    const [loading, setLoading] = useState(false);
    const [discount, setDiscount] = useState(0);
    const [payment, setPayment] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);

    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        supplier: Yup.object().shape({
            id: Yup.string().required('Supplier is required'),
            label: Yup.string().required('Supplier is required'),
        }).required('Supplier is required'),
        total_purchase: Yup.number(),
        total_payment: Yup.number(),
        total_due: Yup.number(),
        total_advance: Yup.number(),
    });

    const hookFormObj = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            supplier: {id: "", label: ""},
            total_purchase: 0,
            total_payment: 0,
            total_due: 0,
            total_advance: 0,
        }
    })

    const { control, handleSubmit, formState: {errors}, reset, setValue, getValues, setError } = hookFormObj
    const [productsErrorMessage, setProductsErrorMessage] = useState<any>(null);

    const supplierValidationSchema = Yup.object().shape({
        name: Yup.string().required(t('formValidation.name')),
        company: Yup.string().required(t('formValidation.company')),
        phone_number: Yup.string().required(t('formValidation.phone_number'))
          .min(11, t('formValidation.phone_len'))
          .max(11, t('formValidation.phone_len')),
        address: Yup.string().required(t('formValidation.address')),
      })

    const { control:supplierControl, handleSubmit:supplierSubmit, formState: { errors:supplierError }, reset:supplierReset, setError:supplierSetError } = useForm({
        resolver: yupResolver(supplierValidationSchema),
        defaultValues: {
            name: "",
            company: "",
            phone_number: "",
            address: "",
        }
    })

    const onSubmit = async (data:any) => {
        try {
            setProductsErrorMessage(null);
            const resData = await purchaseStore.createData(data)
            // console.log(resData);
            
            if (resData){
              reset({
                supplier: {id: "", label: ""},
              })
              purchaseStore.setCartItems([]);
              purchaseStore.cartTotal = 0;
              purchaseStore.cartSubTotal = 0;
              purchaseStore.cartDue = 0;
              purchaseStore.cartDiscount = 0;
              purchaseStore.cartPayment = 0;
              purchaseStore.calculateDue = 0;
              purchaseStore.calculateAdvance = 0;
              purchaseStore.setcartAditionalInputValues(0,0);
              setDiscount(0);
              setPayment(0);
            //   console.log(resData);
              
              navigate('..')
            }
              
          } catch (error:any) {
            console.log('Errors');
            Object.keys(error?.data).map((e:any) => {
              setError(e, {
                type: 'manual', // Use 'manual' for manually triggered errors
                message: error?.data[e],
              });
            })
            setProductsErrorMessage("Please select one products");
          }
    }

    const onsupplierSubmit = async (data:any) => {
        setLoading(true);
        try {
            const resData = await supplierStore.createData(data)
            if (resData){
            supplierReset()
            console.log(resData);
            const object = {id:resData.data.supplier.id, label:resData.data.supplier.name, phone_number:resData.data.supplier.phone_number, address:resData.data.supplier.address}
            setValue('supplier', object);
            setValue('total_purchase', 0);  
            setValue('total_payment', 0);  
            setValue('total_due', 0);  
            setValue('total_advance', 0); 
            handleNewSupplierDialogClose();
            }
            setLoading(false);
        } catch (error:any) {
            Object.keys(error?.data).map((e:any) => {
            setError(e, {
                type: 'manual', // Use 'manual' for manually triggered errors
                message: error?.data[e],
            });
            })
            setLoading(false);
        }
    }
    

    const handleSelectSupplier = (value: any) => {
        console.log("handleSelectSupplier ", value)
        const totalPayment = parseFloat(value?.payment) + parseFloat(value?.due_payment) + parseFloat(value?.advance_payment);
        const totalDue = parseFloat(value?.purchase) - Number(totalPayment);
        let advance = 0;
        let due = 0;
        if(totalDue < 0){
            advance = Math.abs(totalDue);
        }else if(isNaN(totalDue)){
            due = 0;
        }
        else if(totalDue > 0){
            due = totalDue;
        }
        else{
            due = 0;
        }
        console.log(totalDue);
        console.log(due);
        setValue('supplier', value);
        setValue('total_purchase', value?.purchase);  
        setValue('total_payment', isNaN(totalPayment)?0:totalPayment);
        setValue('total_due', due);  
        setValue('total_advance', advance); 
        purchaseStore.supplierDue = due; 
        purchaseStore.supplierAdvance = advance;
        
        if(purchaseStore.supplierAdvance > 0){
            let calDue = purchaseStore.subTotal(purchaseStore.cartItems) - purchaseStore.supplierAdvance;
            if(calDue > 0){
                purchaseStore.calculateDue = calDue;
            }else{
                purchaseStore.calculateAdvance = Math.abs(calDue);
            }
        }else{
            let calDue = purchaseStore.subTotal(purchaseStore.cartItems) + purchaseStore.supplierDue;
            purchaseStore.calculateDue = calDue;
            purchaseStore.calculateAdvance = advance;
        }
    };
    
    const handleNewSupplierDialogClose = () => {
        setDialogOpen(false);
    };

    const handleOpenDialog = () => {
        setDialogOpen(true);
    }

    return (
        <>
        {loading ? 
        <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
            <LinearProgress color="secondary"/>
        </Box>
        : ''
        }
        <Dialog open={dialogOpen} onClose={handleNewSupplierDialogClose}>
            <DialogTitle>{t('suppliers.create')}</DialogTitle>
            <form onSubmit={supplierSubmit(onsupplierSubmit)}>
            <DialogContent>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
                <Controller
                name="name"
                control={supplierControl}
                render={({ field }) => (
                    <TextField
                    {...field}
                    fullWidth
                    id="name"
                    label={t('name')}
                    variant="filled"
                    error={!!supplierError.name}
                    helperText={supplierError.name?.message}
                    autoComplete="off"
                    sx={{
                        margin: 0, // Outer margin
                        '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 0px', // Input padding
                            fontSize: '14px'
                        },
                        '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                        },
                        }}
                    />
                )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                name="company"
                control={supplierControl}
                render={({ field }) => (
                    <TextField
                    {...field}
                    fullWidth
                    id="company"
                    label={t('company')}
                    variant="filled"
                    error={!!supplierError.company}
                    helperText={supplierError.company?.message}
                    autoComplete="off"
                    sx={{
                        margin: 0, // Outer margin
                        '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 0px', // Input padding
                            fontSize: '14px'
                        },
                        '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                        },
                        }}
                    />
                )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                name="phone_number"
                control={supplierControl}
                render={({ field }) => (
                    <TextField
                    {...field}
                    fullWidth
                    id="phone_number"
                    label={t("phone")}
                    variant="filled"
                    error={!!supplierError.phone_number}
                    helperText={supplierError.phone_number?.message}
                    autoComplete="off"
                    sx={{
                        margin: 0, // Outer margin
                        '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 0px', // Input padding
                            fontSize: '14px'
                        },
                        '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                        },
                        }}
                    />
                )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                name="address"
                control={supplierControl}
                render={({ field }) => (
                    <TextField
                    {...field}
                    fullWidth
                    id="address"
                    label={t("address")}
                    variant="filled"
                    error={!!supplierError.address}
                    helperText={supplierError.address?.message}
                    autoComplete="off"
                    sx={{
                        margin: 0, // Outer margin
                        '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 0px', // Input padding
                            fontSize: '14px'
                        },
                        '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                        },
                        }}
                    />
                )}
                />
            </Grid>
            </Grid>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleNewSupplierDialogClose}>
                {t('cancel')}
            </Button>
            <Button color="primary" type="submit">
                {t('save')}
            </Button>
            </DialogActions>
            </form>
        </Dialog>
        <Box sx={{ width: '100%' }}>
            <h4 style={{marginTop:0}}>{t('purchases.create')}</h4>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={4.7}>
                <Controller
                    name="supplier"
                    control={control}
                    render={({ field }) => (
                    <ServerSideSupplierAutocomplete
                        label={t('selectSupplier')}
                        ajaxCallFn={supplierStore.getList} 
                        addNewFn={handleOpenDialog}
                        onOptionSelect={(option) => {
                            field.onChange(option)
                            handleSelectSupplier(option)
                            if (option?.isNew) {
                                reset({
                                    supplier: {id: "", label: ""},
                                })
                                setDialogOpen(true);
                            }

                        }}
                        error={errors.supplier?.id ?? errors.supplier}
                        field={field}
                    />
                    )}
                />
                </Grid>
                <Grid item xs={12} sm={6} md={1.8}>
                <Controller
                    name="total_purchase"
                    control={control}
                    render={({ field }) => (
                        <TextField
                        InputProps={{
                            readOnly: true,
                            disabled: true,
                            startAdornment: (
                            <InputAdornment position="start">
                                Tk.
                            </InputAdornment>
                            ),
                        }}
                        {...field}
                        fullWidth
                        id="total_purchase"
                        label={t('totalPurchase')}
                        variant="filled"
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 14px', // Input padding
                            },
                            '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                            },
                        }}
                        error={!!errors.total_purchase}
                        helperText={errors.total_purchase?.message}
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={1.8}>
                <Controller
                    name="total_payment"
                    control={control}
                    render={({ field }) => (
                        <TextField
                        InputProps={{
                            readOnly: true,
                            disabled: true,
                            startAdornment: (
                            <InputAdornment position="start">
                                Tk.
                            </InputAdornment>
                            ),
                        }}
                        {...field}
                        fullWidth
                        id="total_payment"
                        label={t('totalPayment')}
                        variant="filled"
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 14px', // Input padding
                            },
                            '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                            },
                        }}
                        error={!!errors.total_payment}
                        helperText={errors.total_payment?.message}
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={1.8}>
                <Controller
                    name="total_due"
                    control={control}
                    render={({ field }) => (
                        <TextField
                        InputProps={{
                            readOnly: true,
                            disabled: true,
                            startAdornment: (
                            <InputAdornment position="start">
                                Tk.
                            </InputAdornment>
                            ),
                        }}
                        {...field}
                        fullWidth
                        id="total_due"
                        label={t('totalDue')}
                        variant="filled"
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 14px', // Input padding
                            },
                            '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                            },
                        }}
                        error={!!errors.total_due}
                        helperText={errors.total_due?.message}
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={1.8}>
                <Controller
                    name="total_advance"
                    control={control}
                    render={({ field }) => (
                        <TextField
                        InputProps={{
                            readOnly: true,
                            disabled: true,
                            startAdornment: (
                            <InputAdornment position="start">
                                Tk.
                            </InputAdornment>
                            ),
                        }}
                        {...field}
                        fullWidth
                        id="total_advance"
                        label={t('totalAdvance')}
                        variant="filled"
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 14px', // Input padding
                            },
                            '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                            },
                        }}
                        error={!!errors.total_advance}
                        helperText={errors.total_advance?.message}
                        />
                    )}
                    />
                </Grid>
            </Grid>
            </form>
            {/* Add new item form */}
            <AddNewItemForm/>
            {productsErrorMessage ?  <Box sx={{ color: 'error.main', my: 2 }}>{productsErrorMessage}</Box>: ""}
            <AllItemsList editMode={true} />
            <Button sx={{ mt: 2 }} variant="contained" onClick={() => navigate(-1)}>
                {t('back')}
            </Button>
            <Button sx={{ mt: 2, ml: 2 }} type="button" variant="contained" color="success" onClick={() => handleSubmit(onSubmit)()}>
                {t('save')}
            </Button>
        </Box>
        </>
    )
    
}

export default observer(PurchaseCreate);