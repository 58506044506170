import React, { useRef } from 'react';
import Barcode from 'react-barcode';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PrintIcon from '@mui/icons-material/Print';
import { observer } from 'mobx-react-lite';

interface ProductBarcodeProps {
  productCode: string | null; // Allow productCode to be either string or null
}

const ProductBarcode: React.FC<ProductBarcodeProps> = ({ productCode }) => {
    const [t, i18n] = useTranslation("global");
    const barcodeRef = useRef<HTMLDivElement | null>(null);
    if (!productCode) {
        return <Typography variant="body1">No product code available</Typography>;
    }

    const handlePrint = () => {
        if (!barcodeRef.current) return;
        const printContents = barcodeRef.current.innerHTML;
        const printWindow = window.open('', '_blank');

        if (!printWindow) {
            alert("Unable to open the print window. Please allow popups for this site.");
            return;
        }
        
        // HTML and CSS setup for the print window
        printWindow.document.write(`
          <html>
            <head>
              <title>Print Barcode</title>
              <style>
                body, html {
                width: 58mm; /* Label width */
                height: 30mm; /* Label height */
                margin: 0;
                padding: 0;
                overflow: hidden; /* Prevents extra pages */
                display: flex;
                justify-content: center;
                align-items: center;
                }
                .print-container {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  padding: 20px;
                }
                .barcode {
                  margin-top: 10px;
                }
                @media print {
                  body {
                    transform: scale(1.5); /* Scale to fit label printer size */
                    width: 58mm; /* Adjust according to label printer width */
                    height: auto;
                  }
                }
              </style>
            </head>
            <body onload="window.print(); window.close();">
              <div class="print-container">
                <div class="barcode">${printContents}</div>
              </div>
            </body>
          </html>
        `);
        printWindow.document.close();
      };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" p={2}>
        <Typography variant="h6" gutterBottom>
            {t('productCode')}: {productCode}
        </Typography>
        <div ref={barcodeRef}>
            <Barcode value={productCode} height={50} fontSize={16}/>
        </div>
        <Button
            onClick={handlePrint}
            style={{ marginTop: 10 }}
        >
            <PrintIcon/>
        </Button>
        </Box>
    );
};

export default observer(ProductBarcode);
