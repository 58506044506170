import React from 'react';
import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

interface InfoCardProps {
    title: string | null;
    amount: string | number | undefined;
    backgroundColor: string | null;
}

const InfoCard: React.FC<InfoCardProps> = ({ title, amount, backgroundColor }) => (
  <Card sx={{ minWidth: 200, height:100, backgroundColor }}>
    <CardContent sx={{padding:0, margin:0}}>
      <Typography gutterBottom sx={{backgroundColor:'#15b4eb', color:'#fff', padding:'2px 10px', fontSize:'18px'}}>
        {title}
      </Typography>
      <Typography variant="h6" color="text.primary" sx={{padding:'2px 10px'}}>
        Tk.{amount}
      </Typography>
    </CardContent>
    <CardActions sx={{ justifyContent: 'flex-end', margin:0, padding:0, backgroundColor:'#a0dff4' }}>
      <Button size="small" onClick={()=>''} sx={{color: '#000'}} endIcon={<ArrowRightAltIcon/>}>
        Details
      </Button>
    </CardActions>
  </Card>
);

export default observer(InfoCard);
