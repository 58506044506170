import React from "react";
import { action, makeObservable, observable } from "mobx";
import { IRootStore } from "./rootStore";
import { GridColDef, GridRowsProp } from "@mui/x-data-grid";

export default class ProductStore {
    
    BASE_URL = process.env.REACT_APP_API_URL + '/v1/stocks';
    
    rootStore: IRootStore;
    rowData: GridRowsProp[] = [];
    columns: GridColDef[] = [
        // {field: 'id', headerName: 'Id', width: 100},
        
    ];

    constructor(rootStore: IRootStore) {
      makeObservable(this, {
          rowData: observable,
          columns: observable,
          setRowData: action,
          fetchList: action,
          initProducts: action,
          createData: action,
          getProductUnits: action,
          getData: action,
          updateData: action,
          deleteData: action,
      });
      this.rootStore = rootStore;
  }

  setRowData(values: GridRowsProp[]) {
      this.rowData = values;
  }

  //   Api Calls
  fetchList = async () => {
      try {
          const response = await fetch(this.BASE_URL + '/list', {
              method: 'GET',
              headers: {
                  'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                  'Content-Type': 'application/json', // You can adjust this header as needed
              }
          })
          const data = await response.json();
          if (data.error) {
              this.rootStore.handleError(response.status, `HTTP Request failed: ${response.status} ${response.statusText}`, data);
              return Promise.reject(data)
          } else {
              this.setRowData(data.data.products);
              return Promise.resolve(data)
          }
      } catch (error: any) {
          this.rootStore.handleError(419, "Something went wrong!", error)
      }
  }

    // initProducts
    initProducts = async () => {
        try {
            const response = await fetch(this.BASE_URL + '/initProducts', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                    'Content-Type': 'application/json', // You can adjust this header as needed
                }
            })
            const data = await response.json();
            if (data.error) {
                this.rootStore.handleError(response.status, `HTTP Request failed: ${response.status} ${response.statusText}`, data);
                return Promise.reject(data)
            } else {
                return Promise.resolve(data)
            }
        } catch (error: any) {
            this.rootStore.handleError(419, "Something went wrong!", error)
        }
    }

    // View
    getProductUnits = async (id: number | string) => {
        try {

        const response = await fetch(this.BASE_URL + `/initProductUnits/${id}`, {
            method: 'GET',
            headers: {
            'Authorization': `Bearer ${this.rootStore.authStore.token}`,
            'Content-Type': 'application/json', // You can adjust this header as needed
            }
        })
        const data = await response.json();
        if (data.error) {
            this.rootStore.handleError(response.status, data.message, data);
            return Promise.reject(data)
        } else {
            return Promise.resolve(data)
        }
        } catch (error: any) {
        this.rootStore.handleError(419, "Something went wrong!", error)
        }
    }

    // View
    getData = async (id: number | string) => {
        try {

        const response = await fetch(this.BASE_URL + `/${id}`, {
            method: 'GET',
            headers: {
            'Authorization': `Bearer ${this.rootStore.authStore.token}`,
            'Content-Type': 'application/json', // You can adjust this header as needed
            }
        })
        const data = await response.json();
        if (data.error) {
            this.rootStore.handleError(response.status, data.message, data);
            return Promise.reject(data)
        } else {
            return Promise.resolve(data)
        }
        } catch (error: any) {
        this.rootStore.handleError(419, "Something went wrong!", error)
        }
    }

    // Create
    createData = async (postData: any) => {
        try {
            const response = await fetch(this.BASE_URL, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                },
                body: postData
            })

            const data = await response.json();
            if (data.error) {
                this.rootStore.handleError(response.status, data.message, data);
                return Promise.reject(data)
            } else {
                this.rootStore.alertStore.open({status: "success", message: data.message})
                return Promise.resolve(data)
            }
        } catch (error: any) {
            this.rootStore.handleError(419, "Something went wrong!", error)
        }
    }
    
    // Update
    updateData = async (id: number | string, postData: any) => {
        try {
        const response = await fetch(this.BASE_URL + `/${id}`, {
            method: 'POST',
            headers: {
            'Authorization': `Bearer ${this.rootStore.authStore.token}`,
            },
            body: postData
        })

        const data = await response.json();
        if (data.error) {
            this.rootStore.handleError(response.status,  data.message, data);
            return Promise.reject(data)
        } else {
            this.rootStore.alertStore.open({status: "success", message: data.message})
            return Promise.resolve(data)
        }
        } catch (error: any) {
        this.rootStore.handleError(419, "Something went wrong!", error)
        }
    }

    // Delete
    deleteData = async (id: number | string) => {
        try {
        const response = await fetch(this.BASE_URL + `/${id}`, {
            method: 'DELETE',
            headers: {
            'Authorization': `Bearer ${this.rootStore.authStore.token}`,
            'Content-Type': 'application/json', // You can adjust this header as needed
            }
        })

        const data = await response.json();
        if (data.error) {
            this.rootStore.handleError(response.status, data.message, data);
            return Promise.reject(data)
        } else {
            this.setRowData(this.rowData.filter((e:any) => e.id != id))
            this.rootStore.alertStore.open({status: "success", message: data.message})
            return Promise.resolve(data)
        }
        } catch (error: any) {
        this.rootStore.handleError(419, "Something went wrong!", error)
        }
    }
}