import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../store/rootStore";
import { Box, Grid, LinearProgress } from "@mui/material";
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import { format } from 'date-fns';


const ViewInvoice:React.FC<any> = ({id}) =>{

  
  const { rootStore: { saleStore } } = useStore();
  const [editData, setEditData] = React.useState<any>(null)
  const [loading, setLoading] = React.useState(false);
  const [t, i18n] = useTranslation("global");
  
  const navigate = useNavigate()
  
  const handlePrint = () => {
    window.print();
  };

  const fetchDetails = async () => {
    try {
      setLoading(true);
      if(id){
        const resData = await saleStore.getData(id)
        console.log(resData)
        setEditData(resData)
        setLoading(false);
      }else{
        setLoading(false);
        navigate(-1)
      }
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error('Error while fetching data:', error);
      setLoading(false);
    }
  }

  function ccyFormat(num: number) {
    
    const nFormat = new Intl.NumberFormat(undefined, {minimumFractionDigits: 2});
    return nFormat.format(typeof num !== 'undefined'?num:0);
    // return `${num.toFixed(2)}`;
  }


  const calculateFinalPrice = (original: number, discount: number, quantity: number, unitId: number, default_unit: number, calculate_value: number, calculate_operator: any): number => {
    
    let total = 0;
    if(unitId == default_unit){
      const finalPrice = original - (original *  discount / 100);
      total = finalPrice*quantity;
    }else if(calculate_operator == '*'){
        const finalPrice = (original*calculate_value) - (original *  discount / 100);
        total = finalPrice*quantity
    }else{
        const finalPrice = (original/calculate_value) - ((original/calculate_value) *  discount / 100);
        total = finalPrice*quantity;
    }

    return total;
      
  }
  

  const getDue = () =>{
    let price = (editData?.price-editData?.discount)-editData?.customer_payment?.amount;
    // console.log('uu'+price);
    if(Number.isNaN(price)){
      return 0;
    }
    return price;
    // -editData?.discount)-editData?.customer_payment?.amount
  }

  const getNetTotal = () =>{
    let price = editData?.price-editData?.discount;
    // console.log('uu'+price);
    if(Number.isNaN(price)){
      return 0;
    }
    return price;
    // -editData?.discount)-editData?.customer_payment?.amount
  }

  React.useEffect(() => {
    fetchDetails()
  }, []);


  // React.useEffect(() => {
  //   saleStore.setCartItems([])
  //   // fetchDetails()

  //   return () => {
  //     saleStore.setCartItems([])
  //     saleStore.cartCustomerName= ''
  //     saleStore.cartInvoiceNumber = ''
  //     saleStore.cartCustomerPhone = ''
  //     saleStore.cartCustomerAddress = ''
  //     saleStore.cartDateTime = ''
  //     saleStore.cartTotal = 0;
  //     saleStore.cartSubTotal = 0;
  //     saleStore.cartDiscount = 0;
  //     saleStore.cartPayment = 0;
  //     saleStore.cartDue = 0;
  //   }
  // }, []);

  

  return(
        <>
        {loading ? 
        <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
            <LinearProgress color="secondary"/>
        </Box>
        : ''
        }
        <div className="print-area">
          <div className="print-before-hide">
            <h1 style={{fontSize:30, margin:0, padding:0}}>{t('header.title')}</h1>
          </div>
          <Box pt={0} pb={0} sx={{ width: '100%' }}>
              <Box sx={{ px: 5,  py: 0  }}>
                <h4 className="no-print">{t('saleDetails')}</h4>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{mb: 2}}>
                  <Grid item xs={6}>
                    <TableContainer component={Paper}>
                      <TableRow>
                      <TableCell sx={{padding:'8px 15px' }}>{t('name')}</TableCell>
                      <TableCell sx={{padding:'8px 15px' }}>:</TableCell>
                      <TableCell sx={{padding:'8px 15px' }}>{editData?.customer?.name}</TableCell>
                      </TableRow>
                      <TableRow>
                      <TableCell sx={{padding:'8px 15px' }}>{t('phone')}</TableCell>
                      <TableCell sx={{padding:'8px 15px' }}>:</TableCell>
                      <TableCell sx={{padding:'8px 15px' }}>{editData?.customer?.phone_number}</TableCell>
                      </TableRow>
                      <TableRow>
                      <TableCell sx={{padding:'8px 15px' }}>{t('address')}</TableCell>
                      <TableCell sx={{padding:'8px 15px' }}>:</TableCell>
                      <TableCell sx={{padding:'8px 15px' }}>{editData?.customer?.address}</TableCell>
                      </TableRow>
                    </TableContainer>
                  </Grid>
                  <Grid item xs={6}>
                    <TableContainer component={Paper}>
                      <TableRow>
                      <TableCell sx={{padding:'8px 15px' }}>{t('invoiceNumber')}</TableCell>
                      <TableCell sx={{padding:'8px 15px' }}>:</TableCell>
                      <TableCell sx={{padding:'8px 15px' }}>{editData?.invoice_number}</TableCell>
                      </TableRow>
                      <TableRow>
                      <TableCell sx={{padding:'8px 15px' }}>{t('date')}</TableCell>
                      <TableCell sx={{padding:'8px 15px' }}>:</TableCell>
                      <TableCell sx={{padding:'8px 15px' }}>{format(typeof editData?.created_at === 'undefined'?new Date():editData?.created_at, 'MMMM dd, yyyy')}</TableCell>
                      </TableRow>
                      <TableRow>
                      <TableCell sx={{padding:'8px 15px' }}>{t('time')}</TableCell>
                      <TableCell sx={{padding:'8px 15px' }}>:</TableCell>
                      <TableCell sx={{padding:'8px 15px' }}>{format(typeof editData?.created_at === 'undefined'?new Date():editData?.created_at, 'hh:mm:ss a')}</TableCell>
                      </TableRow>
                    </TableContainer>
                  </Grid>
                </Grid>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" colSpan={6}>
                          {t('details')}
                        </TableCell>
                        <TableCell align="right">{t('price')}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{padding:'8px 15px' }}>{t('sl')}</TableCell>
                        <TableCell sx={{padding:'8px 15px' }}>{t('product')}</TableCell>
                        <TableCell sx={{padding:'8px 15px' }} align="right">{t('price')}</TableCell>
                        <TableCell sx={{padding:'8px 15px' }} align="right">{t('quantity')}</TableCell>
                        <TableCell sx={{padding:'8px 15px' }} align="right">{t('unit')}</TableCell>
                        <TableCell sx={{padding:'8px 15px' }} align="right">{t('discount_per')}</TableCell>
                        <TableCell sx={{padding:'8px 15px' }} align="right">{t('total')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {editData?.product_sales?.map((row:any, i:any) => {
                        return (
                          <TableRow key={i}>
                              <TableCell>
                                {i+1}
                              </TableCell>
                              <TableCell sx={{padding:'8px 15px' }}>
                              {row.product.name}
                              </TableCell>
                              <TableCell sx={{padding:'8px 15px' }} align="right">Tk. {ccyFormat(row.product_price)} {row.product.default_unit.name}</TableCell>
                              <TableCell sx={{padding:'8px 15px' }} align="right">{row.product_quantity}</TableCell>
                              <TableCell sx={{padding:'8px 15px' }} align="right">{row.sale_unit.name}</TableCell>
                              <TableCell sx={{padding:'8px 15px' }} align="right">{row.product_discount}</TableCell>
                              <TableCell sx={{padding:'8px 15px' }} align="right">{ccyFormat(calculateFinalPrice(row.product_price,row.product_discount, row.product_quantity, row.sale_unit_id, row.product.default_unit.id, row.sale_unit.calculate_value, row.sale_unit.operator))}</TableCell>
                          </TableRow>
                        )
                      })}
                      <TableRow>
                        <TableCell rowSpan={6} />
                        <TableCell sx={{ border: 'none' }}/>
                        <TableCell sx={{ border: 'none' }}/>
                        <TableCell sx={{ border: 'none' }}/>
                        <TableCell sx={{ border: 'none' }}/>
                        <TableCell sx={{padding:'8px 15px' }} align="right">{t('subTotal')}</TableCell>
                        <TableCell sx={{padding:'8px 15px' }} align="right">{ccyFormat(editData?.price)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ border: 'none' }}/>
                        <TableCell sx={{ border: 'none' }}/>
                        <TableCell sx={{ border: 'none' }}/>
                        <TableCell sx={{ border: 'none' }}/>
                        <TableCell align="right" sx={{padding:'8px 15px' }}>{t('discount')}</TableCell>
                        <TableCell sx={{padding:'8px 15px' }} align="right">{ccyFormat(editData?.discount)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ border: 'none' }} />
                        <TableCell sx={{ border: 'none' }} />
                        <TableCell sx={{ border: 'none' }}/>
                        <TableCell sx={{ border: 'none' }} />
                        <TableCell sx={{padding:'8px 15px' }} align="right">{t('netTotal')}</TableCell>
                        <TableCell sx={{padding:'8px 15px' }} align="right">{ccyFormat(getNetTotal())}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ border: 'none' }}/>
                        <TableCell sx={{ border: 'none' }}/>
                        <TableCell sx={{ border: 'none' }}/>
                        <TableCell sx={{ border: 'none' }}/>
                        <TableCell align="right" sx={{padding:'8px 15px' }}>{t('payment')}</TableCell>
                        <TableCell sx={{padding:'8px 15px' }} align="right">{ccyFormat(editData?.customer_payment?.amount)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ border: 'none' }}/>
                        <TableCell sx={{ border: 'none' }}/>
                        <TableCell sx={{ border: 'none' }}/>
                        <TableCell sx={{ border: 'none' }}/>
                        <TableCell sx={{padding:'8px 15px' }} align="right">{t('invoiceDue')}</TableCell>
                        <TableCell sx={{padding:'8px 15px' }} align="right">{ccyFormat(getDue())}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
          </Box>
        </div>
        <div className="no-print">
        <Box>
          <Button sx={{ mt: 2 , ml: 5}} type="button" disabled={loading} variant="contained" color="success" onClick={handlePrint}>
            {t('print')}
          </Button>
          <Button sx={{ mt: 2, ml: 2 }} variant="contained" onClick={() => navigate(-1)}>
            {t('back')}
          </Button>
        </Box>
      </div>
    </>
  )
}

export default observer(ViewInvoice);
