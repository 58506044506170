import { createContext, useContext } from "react";
import AuthStore from "./authStore";
import CustomerStore from "./customerStore";
import DialogStore from "./dialogStore";
import SupplierStore from "./supplierStore";
import ProductStore from "./productStore";
import StockStore from "./stockStore";
import AlertStore from "./alertStore";
import SaleStore from "./saleStore";
import SettingStore from "./settingStroe";
import PurchaseStore from "./purchaseStore";
import ReportStore from "./reportStore";
import DashboardStore from "./dashboardStore";


if(process.env.NODE_ENV === "development"){
    const {enableLogging} = require('mobx-logger');
    enableLogging();
}

export interface IRootStore {
    authStore: AuthStore;
    saleStore: SaleStore;
    purchaseStore: PurchaseStore;
    customerStore: CustomerStore;
    supplierStore: SupplierStore;
    productStore: ProductStore;
    stockStore: StockStore;
    dialogStore: DialogStore;
    alertStore: AlertStore;
    reportStore: ReportStore;
    dashboardStore: DashboardStore;
    handleError: Function
}

export class RootStore implements IRootStore {

    authStore: AuthStore;
    saleStore: SaleStore;
    purchaseStore: PurchaseStore;
    customerStore: CustomerStore;
    supplierStore: SupplierStore;
    productStore: ProductStore;
    stockStore: StockStore;
    dialogStore: DialogStore;
    alertStore: AlertStore;
    reportStore: ReportStore;
    settingStore: SettingStore;
    dashboardStore: DashboardStore;

    constructor(){
        console.log('RootStore');
        this.authStore = new AuthStore(this);
        this.saleStore = new SaleStore(this);
        this.purchaseStore = new PurchaseStore(this);
        this.customerStore = new CustomerStore(this);
        this.supplierStore = new SupplierStore(this);
        this.productStore = new ProductStore(this);
        this.stockStore = new StockStore(this);
        this.dialogStore = new DialogStore(this);
        this.alertStore = new AlertStore(this);
        this.reportStore = new ReportStore(this);
        this.settingStore = new SettingStore(this);
        this.dashboardStore = new DashboardStore(this);
    }

    public handleError = (errorCode: number | null = null, errorMessage: string, errorData: any) => {
        console.error("handleError: ", errorData);
        if(errorCode === 403){
            this.authStore.setIsAuthenticated(false);
            return null;
        }
        this.alertStore.open({status: "error", message: errorMessage})
    }
}

const rootStoreContext = createContext({
    rootStore: new RootStore()
})

export const useStore = () => useContext(rootStoreContext);