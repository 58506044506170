import { observer } from "mobx-react-lite";
import React, {useState} from "react";
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, TextField, Button } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom'
import { useStore } from '../../../store/rootStore';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';


const SupplierCreate = () => {

    const { rootStore: { supplierStore } } = useStore();
    const { createData } = supplierStore;
    const [t, i18n] = useTranslation("global");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('formValidation.name')),
        company: Yup.string().required(t('formValidation.company')),
        phone_number: Yup.string().required(t('formValidation.phone_number'))
          .min(11, t('formValidation.phone_len'))
          .max(11, t('formValidation.phone_len')),
        address: Yup.string().required(t('formValidation.address')),
      })

    const { control, handleSubmit, formState: { errors }, reset, setError } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            name: "",
            company: "",
            phone_number: "",
            address: "",
        }
    })

    const onSubmit = async (data:any) => {
        setLoading(true);
        try {
            const resData = await createData(data)
            if (resData){
            reset()
            navigate('..')
            }
            setLoading(false);
        } catch (error:any) {
            Object.keys(error?.data).map((e:any) => {
            setError(e, {
                type: 'manual', // Use 'manual' for manually triggered errors
                message: error?.data[e],
            });
            })
            setLoading(false);
        }
    }

    return (
        <>
        {loading ? 
        <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
            <LinearProgress color="secondary"/>
        </Box>
        : ''
        }
        
        <Box sx={{ width: '100%' }}>
            <h4>{t('suppliers.create')}</h4>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                    <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                        <TextField
                        {...field}
                        fullWidth
                        id="name"
                        label={t('name')}
                        variant="filled"
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        autoComplete="off"
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                              height: '40px', // Input height
                              padding: '0 0px', // Input padding
                              fontSize: '14px'
                            },
                            '& .MuiInputLabel-root': {
                              padding: '0',
                              marginTop: '-5px',
                              fontSize: '14px',
                            },
                          }}
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                    name="company"
                    control={control}
                    render={({ field }) => (
                        <TextField
                        {...field}
                        fullWidth
                        id="company"
                        label={t('company')}
                        variant="filled"
                        error={!!errors.company}
                        helperText={errors.company?.message}
                        autoComplete="off"
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                              height: '40px', // Input height
                              padding: '0 0px', // Input padding
                              fontSize: '14px'
                            },
                            '& .MuiInputLabel-root': {
                              padding: '0',
                              marginTop: '-5px',
                              fontSize: '14px',
                            },
                          }}
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                    name="phone_number"
                    control={control}
                    render={({ field }) => (
                        <TextField
                        {...field}
                        fullWidth
                        id="phone_number"
                        label={t("phone")}
                        variant="filled"
                        error={!!errors.phone_number}
                        helperText={errors.phone_number?.message}
                        autoComplete="off"
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                              height: '40px', // Input height
                              padding: '0 0px', // Input padding
                              fontSize: '14px'
                            },
                            '& .MuiInputLabel-root': {
                              padding: '0',
                              marginTop: '-5px',
                              fontSize: '14px',
                            },
                          }}
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                    name="address"
                    control={control}
                    render={({ field }) => (
                        <TextField
                        {...field}
                        fullWidth
                        id="address"
                        label={t("address")}
                        variant="filled"
                        error={!!errors.address}
                        helperText={errors.address?.message}
                        autoComplete="off"
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                              height: '40px', // Input height
                              padding: '0 0px', // Input padding
                              fontSize: '14px'
                            },
                            '& .MuiInputLabel-root': {
                              padding: '0',
                              marginTop: '-5px',
                              fontSize: '14px',
                            },
                          }}
                        />
                    )}
                    />
                </Grid>
                </Grid>
                <Button sx={{ mt: 2 }} variant="contained" onClick={() => navigate(-1)}>
                {t('back')}
                </Button>
                <Button sx={{ mt: 2, ml: 2 }} type="submit" variant="contained" color="success">
                {t('save')}
                </Button>
            </form>
        </Box>
        </>
    )
}

export default observer(SupplierCreate);