import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Outlet, Link, Navigate, useNavigate } from 'react-router-dom';
import userImg from '../layouts/user.png';
import { useStore } from '../../store/rootStore';
import { observer } from 'mobx-react-lite';
import { menu } from '../../config/menu';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import LinearProgress from '@mui/material/LinearProgress';
import Collapse from "@mui/material/Collapse";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Theme } from '@emotion/react';
import { makeStyles, createStyles } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import AppDialog from '../dialog/AppDialog';
import AppAlert from '../alert/AppAlert';
import MoneyIcon from '@mui/icons-material/Money';
import { encryptId } from '../utils/cryptoUtils';

const drawerWidth = 240;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window?: () => Window;
}

const settings = [
    {
        label: 'Profile',
        isLink: true,
        path: '/app/profile',
    },
    {
        label: 'Logout',
        isLink: false,
        path: '/app/logout',
    }
];

const BaseLayout = (props: Props) => {
  const { window } = props;
  const [t, i18n] = useTranslation("global");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isCollapse, setIsCollapse] = React.useState(false);
  const [activeCollapse, setActiveCollapse] = React.useState(null);
  const [activeSubmenu, setActiveSubmenu] = React.useState(null);
  const [lang, setLang] = React.useState(localStorage.getItem('moddhobittolang'));
  
  //start new account menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  //end new account menu
  const navigate = useNavigate();

  const {rootStore: {authStore}} = useStore();

  //start new account menu
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (isLink:boolean=true) => {
    if(!isLink){
      logout();
    }
    setAnchorEl(null);
  };
  //end new account menu

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };
  

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const logout = async () => {
    setLoading(true);
    try {
        const resData = await authStore.logout();
        setLoading(false);
    } catch (error) {
        setLoading(false);
        console.log(error);
    }
  }

  const handleCollapse = (label:any) => {
    if(activeCollapse === label){
      setActiveCollapse(null);
      setIsCollapse(!isCollapse);
    }else{
      setActiveCollapse(label);
      setIsCollapse(true);
    }
  };

  const handleSubmenu = (label:any) => {
      setActiveSubmenu(label);
  };

  const singleMenuItem = (label:any) => {
    setActiveCollapse(label);
    setActiveSubmenu(null);
    setIsCollapse(false);
  };

  const handleChangeLanguage = (lang: string) => {
    localStorage.setItem('moddhobittolang', lang);
    setLang(lang);
    i18n.changeLanguage(lang);
  }

  const drawer = (
    <div>
      {/* <Toolbar>Logo</Toolbar> */}
      <div style={{height: "40px", display:"flex", justifyContent:"center", alignItems:"center"}}>Logo</div>
      <Divider />
      <List>
        {menu.map((menu, index) => {
          if(menu.submenu.length > 0){
            if(menu.roles && menu.roles.some(item => item === authStore.role)){
              return (
                <>
                <ListItem key={index} disablePadding onClick={()=>handleCollapse(menu.label)}>
                  <ListItemButton sx={ activeCollapse === menu.label ? {padding:'3px 12px', backgroundColor: "#f2f1f7"} : {padding:'3px 12px'}}>
                    <ListItemIcon>
                      {menu.icon}
                    </ListItemIcon>
                    <ListItemText sx={{ml:"-20px"}} primaryTypographyProps={lang === null || lang === 'en' ? {fontSize: '16px'}:{fontSize: '20px', fontFamily:"BenSen"}} primary={t(`menu.${menu.text}`)} />
                    {isCollapse && activeCollapse === menu.label ? <ExpandLessIcon /> : <ExpandMoreIcon/>}
                  </ListItemButton>
                </ListItem>
                <Collapse in={isCollapse && activeCollapse === menu.label} timeout="auto" unmountOnExit>
                  {menu.submenu.map((submenu, index) => {
                    if(submenu.roles && submenu.roles.some(item => item === authStore.role)){
                      return (
                        <ListItem key={index} disablePadding sx={ activeCollapse === menu.label && activeSubmenu === submenu.label ? {display: "block", ml: "20px", backgroundColor: "#f2f1f7"} : {display: "block", ml: "20px"}} onClick={()=>handleSubmenu(submenu.label)}>
                          <ListItemButton
                            component={Link} to={submenu.link}
                            sx={{padding:'2px 10px'}}
                          >
                            <ListItemIcon>
                              {submenu.icon}
                            </ListItemIcon>
                            <ListItemText sx={{ml:"-20px"}} primaryTypographyProps={lang === null || lang === 'en' ?{fontSize: '15px'}:{fontSize: '19px', fontFamily:"BenSen"}} primary={t(`menu.${submenu.text}`)}/>
                          </ListItemButton>
                        </ListItem>
                      )
                    }
                  })}
                </Collapse>
                </>
              )
            }            
          }else{
            if(menu.roles && menu.roles.some(item => item === authStore.role)){
              return (
                <ListItem key={index} disablePadding onClick={()=>singleMenuItem(menu.label)}>
                  <ListItemButton component={Link} to={menu.link} sx={ activeCollapse === menu.label ? {padding:'3px 12px', backgroundColor: "#f2f1f7"} : {padding:'3px 12px'}}>
                    <ListItemIcon>
                      {menu.icon}
                    </ListItemIcon>
                    <ListItemText sx={{ml:"-20px"}} primaryTypographyProps={lang === null || lang === 'en' ? {fontSize: '16px'}:{fontSize: '20px', fontFamily:"BenSen"}} primary={t(`menu.${menu.text}`)} />
                  </ListItemButton>
                </ListItem>
              )
            }
          }
        })}
      </List>
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
    {loading ? 
    <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
        <LinearProgress color="secondary"/>
    </Box>
    : ''
    }
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar variant='dense' disableGutters sx={{ minHeight: 40, height: 40, padding:"0px 10px" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={lang === null || lang === 'en' ? { flexGrow: 1, fontSize:"18px" }:{ flexGrow: 1, fontFamily:"BenSen", fontSize:"25px" }}>
            {t("header.title")}
          </Typography>
          {/* <Button color="inherit">Login</Button> */}
          <Box sx={{ flexGrow: 0 }}>
            {lang === null || lang === 'en' ? 
              <Button variant="outlined" onClick={()=>handleChangeLanguage("bn")} sx={{padding:"2px 0px", color:"white", borderColor:"white", ':hover':{borderColor:"white", backgroundColor:"#076bcf"}}}>বাংলা</Button>
            :
              <Button variant="outlined" onClick={()=>handleChangeLanguage("en")} sx={{padding:"2px 0px", marginRight:"10px", color:"white", borderColor:"white", ':hover':{borderColor:"white", backgroundColor:"#076bcf"}}}>EN</Button>
            }
            
            
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <Avatar sx={{ width: 28, height: 28 }}>M</Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={()=>handleClose()}
            onClick={()=>handleClose()}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&::before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={()=>handleClose()} sx={lang === null || lang === 'en' ? {fontSize:"16px"} : {fontSize:"14px"}} component={Link} to={'/app/profile'}>
              <Avatar /> {t("accountSetting.profile")}
            </MenuItem>
            <MenuItem onClick={()=>handleClose()} sx={lang === null || lang === 'en' ? {fontSize:"16px"} : {fontSize:"14px"}} component={Link} to={'/app/myaccount'}>
              <MoneyIcon sx={{
                marginLeft:0,
                marginRight:1.4,
                fill:'grey'
              }} /> {t("accountSetting.myAccount")}
            </MenuItem>
            <Divider />
            {/* <MenuItem onClick={()=>handleClose()} sx={lang === null || lang === 'en' ? {fontSize:"16px"} : {fontSize:"14px"}} component={Link} to={'/app/settings'}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              {t("accountSetting.settings")}
            </MenuItem> */}
            <MenuItem onClick={()=>handleClose(false)} sx={lang === null || lang === 'en' ? {fontSize:"16px"} : {fontSize:"14px"}}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              {t("accountSetting.logout")}
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        {/* Components */}
        <AppAlert/>
        <Outlet/>
        <AppDialog/>
      </Box>
    </Box>
    </>
  );
}

export default observer(BaseLayout);