import React from "react";
import { action, makeObservable, observable } from "mobx";
import { IRootStore } from "./rootStore";
import { GridColDef, GridRowsProp, GridValidRowModel } from "@mui/x-data-grid";
import { Button, ListItem, ListItemButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Define your row type
interface CategoryRowModel extends GridValidRowModel {
  name: string;
}

interface UnitRowModel extends GridValidRowModel {
  name: string;
  calculate_value: number;
  operator: string;
}

interface UserRowModel extends GridValidRowModel {
  name: string;
  email: string;
  phone: string;
  role: string;
}

export default class SettingStore {
    
    BASE_URL = process.env.REACT_APP_API_URL + '/v1/settings';
    
    rootStore: IRootStore;
    categoryRowData: CategoryRowModel[] = [];
    searchCategoryData: CategoryRowModel[] = [];
    unitRowData: UnitRowModel[] = [];
    searchUnitData: UnitRowModel[] = [];
    userRowData: UserRowModel[] = [];
    searchUserData: UserRowModel[] = [];
    columns: GridColDef[] = [];

    constructor(rootStore: IRootStore) {
      makeObservable(this, {
        categoryRowData: observable,
        columns: observable,
        fetchCategoryList: action,
        setCategoryRowData: action,
        setUnitRowData: action,
        fetchUnitList: action,
        createCategoryData: action,
        getCategoryData: action,
        updateCategoryData: action,
        deleteCategoryData: action,
        createUnitData: action,
        getUnitData: action,
        updateUnitData: action,
        deleteUnitData: action,
        setUserRowData: action,
        fetchUserList: action,
        createUserData: action,
        getUserData: action,
        updateUserData: action,
        deleteUserData: action,
        getProfileData: action,
        getProfileDataForUpdate: action,
        updateProfileData: action,

      });
      this.rootStore = rootStore;
  }

  setCategoryRowData(values: CategoryRowModel[]) {
      this.categoryRowData = values;
      this.searchCategoryData = values;
  }

  setUnitRowData(values: UnitRowModel[]) {
      this.unitRowData = values;
      this.searchUnitData = values;
  }

  setUserRowData(values: UserRowModel[]) {
    this.userRowData = values;
    this.searchUserData = values;
}

  //   Api Calls
  fetchCategoryList = async () => {
      try {
          const response = await fetch(this.BASE_URL + '/product_category', {
              method: 'GET',
              headers: {
                  'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                  'Content-Type': 'application/json', // You can adjust this header as needed
              }
          })
          const data = await response.json();
          if (data.error) {
              this.rootStore.handleError(response.status, `HTTP Request failed: ${response.status} ${response.statusText}`, data);
              return Promise.reject(data)
          } else {
              this.setCategoryRowData(data.data.categories);
              return Promise.resolve(data)
          }
      } catch (error: any) {
          this.rootStore.handleError(419, "Something went wrong!", error)
      }
  }

  fetchUnitList = async () => {
    try {
        const response = await fetch(this.BASE_URL + '/units', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                'Content-Type': 'application/json', // You can adjust this header as needed
            }
        })
        const data = await response.json();
        if (data.error) {
            this.rootStore.handleError(response.status, `HTTP Request failed: ${response.status} ${response.statusText}`, data);
            return Promise.reject(data)
        } else {
            this.setUnitRowData(data.data.units);
            return Promise.resolve(data)
        }
    } catch (error: any) {
        this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

  fetchUserList = async () => {
    try {
        const response = await fetch(this.BASE_URL + '/users', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                'Content-Type': 'application/json', // You can adjust this header as needed
            }
        })
        const data = await response.json();
        if (data.error) {
            this.rootStore.handleError(response.status, `HTTP Request failed: ${response.status} ${response.statusText}`, data);
            return Promise.reject(data)
        } else {
            this.setUserRowData(data.data.users);
            return Promise.resolve(data)
        }
    } catch (error: any) {
        this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

  // Create
  createCategoryData = async (postData: any) => {
    try {
        const response = await fetch(this.BASE_URL + `/createCategory`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                'Content-Type': 'application/json', // You can adjust this header as needed
            },
            body: JSON.stringify(postData)
        })

        const data = await response.json();
        if (data.error) {
            this.rootStore.handleError(response.status, data.message, data);
            return Promise.reject(data)
        } else {
            this.rootStore.alertStore.open({status: "success", message: data.message})
            return Promise.resolve(data)
        }
    } catch (error: any) {
        this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

  // View
  getCategoryData = async (id: number | string) => {
    try {

      const response = await fetch(this.BASE_URL + `/getCategoryData/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.rootStore.authStore.token}`,
          'Content-Type': 'application/json', // You can adjust this header as needed
        }
      })
      const data = await response.json();
      if (data.error) {
        this.rootStore.handleError(response.status, data.message, data);
        return Promise.reject(data)
      } else {
        return Promise.resolve(data)
      }
    } catch (error: any) {
      this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

  // Update
  updateCategoryData = async (postData: any) => {
    try {
      const response = await fetch(this.BASE_URL + `/updateCategory`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${this.rootStore.authStore.token}`,
          'Content-Type': 'application/json', // You can adjust this header as needed
        },
        body: JSON.stringify(postData)
      })

      const data = await response.json();
      if (data.error) {
        this.rootStore.handleError(response.status,  data.message, data);
        return Promise.reject(data)
      } else {
        this.rootStore.alertStore.open({status: "success", message: data.message})
        return Promise.resolve(data)
      }
    } catch (error: any) {
      this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

  // Delete
  deleteCategoryData = async (id: number | string) => {
    try {
      const response = await fetch(this.BASE_URL + `/category/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${this.rootStore.authStore.token}`,
          'Content-Type': 'application/json', // You can adjust this header as needed
        }
      })

      const data = await response.json();
      if (data.error) {
        this.rootStore.handleError(response.status, data.message, data);
        return Promise.reject(data)
      } else {
        this.setCategoryRowData(this.categoryRowData.filter((e:any) => e.id != id))
        this.rootStore.alertStore.open({status: "success", message: data.message})
        return Promise.resolve(data)
      }
    } catch (error: any) {
      this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

  // Create
  createUnitData = async (postData: any) => {
    try {
        const response = await fetch(this.BASE_URL + `/createUnit`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                'Content-Type': 'application/json', // You can adjust this header as needed
            },
            body: JSON.stringify(postData)
        })

        const data = await response.json();
        if (data.error) {
            this.rootStore.handleError(response.status, data.message, data);
            return Promise.reject(data)
        } else {
            this.rootStore.alertStore.open({status: "success", message: data.message})
            return Promise.resolve(data)
        }
    } catch (error: any) {
        this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

  // View
  getUnitData = async (id: number | string) => {
    try {

      const response = await fetch(this.BASE_URL + `/getUnitData/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.rootStore.authStore.token}`,
          'Content-Type': 'application/json', // You can adjust this header as needed
        }
      })
      const data = await response.json();
      if (data.error) {
        this.rootStore.handleError(response.status, data.message, data);
        return Promise.reject(data)
      } else {
        return Promise.resolve(data)
      }
    } catch (error: any) {
      this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

  // Update
  updateUnitData = async (postData: any) => {
    try {
      const response = await fetch(this.BASE_URL + `/updateUnit`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${this.rootStore.authStore.token}`,
          'Content-Type': 'application/json', // You can adjust this header as needed
        },
        body: JSON.stringify(postData)
      })

      const data = await response.json();
      if (data.error) {
        this.rootStore.handleError(response.status,  data.message, data);
        return Promise.reject(data)
      } else {
        this.rootStore.alertStore.open({status: "success", message: data.message})
        return Promise.resolve(data)
      }
    } catch (error: any) {
      this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

  // Delete
  deleteUnitData = async (id: number | string) => {
    try {
      const response = await fetch(this.BASE_URL + `/unit/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${this.rootStore.authStore.token}`,
          'Content-Type': 'application/json', // You can adjust this header as needed
        }
      })

      const data = await response.json();
      if (data.error) {
        this.rootStore.handleError(response.status, data.message, data);
        return Promise.reject(data)
      } else {
        this.setUnitRowData(this.unitRowData.filter((e:any) => e.id != id))
        this.rootStore.alertStore.open({status: "success", message: data.message})
        return Promise.resolve(data)
      }
    } catch (error: any) {
      this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

  // Create
  createUserData = async (postData: any) => {
    try {
        const response = await fetch(this.BASE_URL + `/createUser`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                'Content-Type': 'application/json', // You can adjust this header as needed
            },
            body: JSON.stringify(postData)
        })

        const data = await response.json();
        if (data.error) {
            this.rootStore.handleError(response.status, data.message, data);
            return Promise.reject(data)
        } else {
            this.rootStore.alertStore.open({status: "success", message: data.message})
            return Promise.resolve(data)
        }
    } catch (error: any) {
        this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

  // View
  getUserData = async (id: number | string) => {
    try {

      const response = await fetch(this.BASE_URL + `/getUserData/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.rootStore.authStore.token}`,
          'Content-Type': 'application/json', // You can adjust this header as needed
        }
      })
      const data = await response.json();
      if (data.error) {
        this.rootStore.handleError(response.status, data.message, data);
        return Promise.reject(data)
      } else {
        return Promise.resolve(data)
      }
    } catch (error: any) {
      this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

  // Update
  updateUserData = async (postData: any) => {
    try {
      const response = await fetch(this.BASE_URL + `/updateUser`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${this.rootStore.authStore.token}`,
          'Content-Type': 'application/json', // You can adjust this header as needed
        },
        body: JSON.stringify(postData)
      })

      const data = await response.json();
      if (data.error) {
        this.rootStore.handleError(response.status,  data.message, data);
        return Promise.reject(data)
      } else {
        this.rootStore.alertStore.open({status: "success", message: data.message})
        return Promise.resolve(data)
      }
    } catch (error: any) {
      this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

  // Delete
  deleteUserData = async (id: number | string) => {
    try {
      const response = await fetch(this.BASE_URL + `/user/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${this.rootStore.authStore.token}`,
          'Content-Type': 'application/json', // You can adjust this header as needed
        }
      })

      const data = await response.json();
      if (data.error) {
        this.rootStore.handleError(response.status, data.message, data);
        return Promise.reject(data)
      } else {
        this.setUserRowData(this.userRowData.filter((e:any) => e.id != id))
        this.rootStore.alertStore.open({status: "success", message: data.message})
        return Promise.resolve(data)
      }
    } catch (error: any) {
      this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

  // View
  getProfileData = async (token: string) => {
    try {

      const response = await fetch(this.BASE_URL + `/getProfileData`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.rootStore.authStore.token}`,
          'Content-Type': 'application/json', // You can adjust this header as needed
        }
      })
      const data = await response.json();
      if (data.error) {
        this.rootStore.handleError(response.status, data.message, data);
        return Promise.reject(data)
      } else {
        return Promise.resolve(data)
      }
    } catch (error: any) {
      this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

  // View
  getProfileDataForUpdate = async (token: string) => {
    try {

      const response = await fetch(this.BASE_URL + `/getProfileDataForUpdate`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.rootStore.authStore.token}`,
          'Content-Type': 'application/json', // You can adjust this header as needed
        }
      })
      const data = await response.json();
      if (data.error) {
        this.rootStore.handleError(response.status, data.message, data);
        return Promise.reject(data)
      } else {
        return Promise.resolve(data)
      }
    } catch (error: any) {
      this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

  // Update
  updateProfileData = async (postData: any) => {
    try {
      const response = await fetch(this.BASE_URL + `/updateProfile`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${this.rootStore.authStore.token}`,
          'Content-Type': 'application/json', // You can adjust this header as needed
        },
        body: JSON.stringify(postData)
      })

      const data = await response.json();
      if (data.error) {
        this.rootStore.handleError(response.status,  data.message, data);
        return Promise.reject(data)
      } else {
        this.rootStore.alertStore.open({status: "success", message: data.message})
        return Promise.resolve(data)
      }
    } catch (error: any) {
      this.rootStore.handleError(419, "Something went wrong!", error)
    }
  }

}