import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useStore } from '../../../store/rootStore';
import { observer } from 'mobx-react-lite';
import LinearProgress from '@mui/material/LinearProgress';
import { Button, TextField, Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';

const SaleList = () => {
    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(false);
    const [t, i18n] = useTranslation("global");

    const {rootStore: {saleStore, authStore}} = useStore();

    const initTable = async () => {
        setLoading(true);
        try {
            const resData = await saleStore.fetchList();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    useEffect(()=>{
        initTable();
    }, []);

    const confirmDelete = async(id:any)=>{
      setLoading(true);
      /*
      try {
        const resData = await saleStore.deleteData(id);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }*/
    }

    const deleteData = (params:any) => {

      saleStore.rootStore.dialogStore.openDialog({
        confirmFn: () => confirmDelete(params.id),
        dialogText: t("sales.deleteConfirmText")
      })
      // customerStore.deleteDialog(params);
      // setLoading(false);
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.toLowerCase();
      setSearchText(event.target.value);

      saleStore.rowData = saleStore.searchData.filter((row)=>{
        return row.invoice_number.toLowerCase().includes(value)
         || row.customer_name.toLowerCase().includes(value)
         || row.formatted_date.toLowerCase().includes(value)
         || row.formatted_price.replace(/,/g, '').includes(value)
         || row.netAmount.replace(/,/g, '').includes(value)
         || row.formatted_discount.replace(/,/g, '').includes(value)
         || row.formatted_time.toLowerCase().includes(value);
      })
    };

  return (
    <>
    {loading ? 
    <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
        <LinearProgress color="secondary"/>
    </Box>
    : ''
    }
    <Box sx={{ height: 400, width: '100%' }}>
      <h4>{t('sales.list')}</h4>
       <Button variant="contained" sx={{mb:2}} component={Link} to="create"><PersonAddIcon sx={{mr:1}}/>{t('addNew')}</Button>
       <Toolbar style={{padding:0}}>
        <TextField
          variant="outlined"
          label={t('search')}
          value={searchText}
          onChange={handleSearch}
          sx={{
            margin: 0, // Outer margin
            '& .MuiInputBase-root': {
              height: '40px', // Input height
              padding: '0 0px', // Input padding
              fontSize: '14px'
            },
            '& .MuiInputLabel-root': {
              padding: '0',
              marginTop: '-5px',
              fontSize: '14px',
            },
          }}
        />
      </Toolbar>
      <DataGrid
        rows={saleStore.rowData}
        columns={
          [
            {
              field: "id",
              headerName: "#",
              filterable: false,
              width: 50,
              renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1
              },
              { field: 'invoice_number', headerName: t('invoiceNumber'), width: 80 },
              { field: 'customer_name', headerName: t('customerName'), width: 150 },
              { field: 'quantity', headerName: t('quantity'), width: 80, align:'center' },
              { field: 'formatted_price', headerName: t('price'), width: 100, align:'right', headerAlign:'right' },
              { field: 'formatted_discount', headerName: t('discount'), width: 100, align:'right', headerAlign:'right' },
              { field: 'netAmount', headerName: t('netAmount'), width: 100, align:'right', headerAlign:'right' },
              { field: 'formatted_date', headerName: t('date'), width: 100 },
              { field: 'formatted_time', headerName: t('time'), width: 150 },
              {
                  field: 'actions',
                  headerName: t('action'),
                  width: 150,
                  sortable: false,
                  filterable: false,
                  renderCell: (params) => (
                      <>
                          <Button style={{maxWidth:'40px', minWidth:'40px'}} component={Link} to={'view/'+params.id}><LocalPrintshopIcon/></Button>
                          {authStore.role==='Admin' ? <Button style={{maxWidth:'40px', minWidth:'40px'}} component={Link} to={'edit/'+params.id}><EditIcon/></Button>:""}
                          {authStore.role==='Admin' ? <Button style={{maxWidth:'40px', minWidth:'40px'}} onClick={()=>deleteData(params)}><DeleteIcon/></Button>:""}
                      </>
                  )
              }
          ]
        }
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 25, 100]}
      />
    </Box>
    </>
  );
}

export default observer(SaleList);
