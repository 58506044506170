import React from "react";
import { action, makeObservable, observable } from "mobx";
import { IRootStore } from "./rootStore";
import { GridColDef, GridRowsProp, GridValidRowModel } from "@mui/x-data-grid";
import { Button, ListItem, ListItemButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface DashboardDetails {
    purchase: string;
    sales: string;
    customerPayment: string;
    supplierPayment: string;
    extraIncome: string;
    extraExpense: string;
    cash?:string;
}

interface CustomerDueReportRowModel extends GridValidRowModel {
    name: string;
    phone_number: string;
    address: string;
    purchase: string;
    payment: string;
    due_payment: string;
    advance_payment: string;
    totalDue: string;
    lastPaymentDate: string;
}

export default class DashboardStore {
    
    BASE_URL = process.env.REACT_APP_API_URL + '/v1/dashboard';
    
    rootStore: IRootStore;
    dashboardData: DashboardDetails = {
        purchase:'0.00',
        sales:'0.00',customerPayment:'0.00',
        supplierPayment:'0.00',
        extraIncome:'0.00',
        extraExpense:'0.00',
        cash:'0.00'
    }

    customerDueReportRowData: CustomerDueReportRowModel[] = [];
    searchCustomerDueReportData: CustomerDueReportRowModel[] = [];

    columns: GridColDef[] = [
        // {field: 'id', headerName: 'Id', width: 100},
    ];

    constructor(rootStore: IRootStore) {
        makeObservable(this, {
            columns: observable,
            dashboardData: observable,
            customerDueReportRowData: observable,
            searchCustomerDueReportData: observable,
            setDashboardData: action,
            fetchDashboardDetails: action,
            setCustomerDueReportRowData: action,
            fetchCustomerDueReportList: action,
            updateDueDate: action,
        });
        this.rootStore = rootStore;
    }

    setCustomerDueReportRowData(values: CustomerDueReportRowModel[]){
        this.customerDueReportRowData = values;
        this.searchCustomerDueReportData = values;
    }

    setDashboardData(value:DashboardDetails){
        this.dashboardData = value;
    }
    // Show
    fetchDashboardDetails = async (date: string) => {
        try {
            
            const response = await fetch(this.BASE_URL + `/dashboardDetails/${date}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                    'Content-Type': 'application/json'
                }
            })

            const data = await response.json();
            if (data.error) {
                this.rootStore.handleError(response.status, `HTTP Request failed: ${response.status} ${response.statusText}`, data);
                return Promise.reject(data)
            } else {
                console.log(data);
                this.setDashboardData(data.data.cards);
                return Promise.resolve(data)
            }

        } catch (error) {
            this.rootStore.handleError(419, "Something went wrong!", error);
        }
    }

    // Show
    fetchCustomerDueReportList = async (date: string) => {
        try {
            
            const response = await fetch(this.BASE_URL + `/customerDueReports/${date}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${this.rootStore.authStore.token}`,
                    'Content-Type': 'application/json'
                }
            })

            const data = await response.json();
            if (data.error) {
                this.rootStore.handleError(response.status, `HTTP Request failed: ${response.status} ${response.statusText}`, data);
                return Promise.reject(data)
            } else {
                this.setCustomerDueReportRowData(data.data.customers);
                return Promise.resolve(data)
            }

        } catch (error) {
            this.rootStore.handleError(419, "Something went wrong!", error);
        }
    }

    // Update
    updateDueDate = async (postData: any) => {
        try {
        const response = await fetch(this.BASE_URL + `/updateDueDate`, {
            method: 'POST',
            headers: {
            'Authorization': `Bearer ${this.rootStore.authStore.token}`,
            'Content-Type': 'application/json', // You can adjust this header as needed
            },
            body: JSON.stringify(postData)
        })

        const data = await response.json();
        if (data.error) {
            this.rootStore.handleError(response.status,  data.message, data);
            return Promise.reject(data)
        } else {
            this.rootStore.alertStore.open({status: "success", message: data.message})
            return Promise.resolve(data)
        }
        } catch (error: any) {
        this.rootStore.handleError(419, "Something went wrong!", error)
        }
    }
}