import { observer } from "mobx-react-lite";
import React, {useState, useEffect} from "react";
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, TextField, FormControlLabel, Checkbox, Button, CardMedia, Card, MenuItem } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom'
import { useStore } from '../../../store/rootStore';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';


const StockCreate = () => {

    const [imageUrl, setImageUrl] = useState<string|null>(null);
    const [products, setProducts] = useState<any[]>([]);
    const [units, setUnits] = useState<any[]>([]);
    const { rootStore: { stockStore } } = useStore();
    const { createData, initProducts, getProductUnits } = stockStore;
    const [t, i18n] = useTranslation("global");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    

    const validationSchema = Yup.object().shape({
        product_id: Yup.string().required('Product is required'),
        quantity: Yup.number().required('Stock quantity is required').min(1, 'Minimum quantity is 1'),
        unit: Yup.string().required('You must select an option'),
    })

    const { control, register, handleSubmit, setValue, formState: { errors }, reset, setError } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            product_id: "",
            quantity: 0,
            unit: "",
        }
    })

    const onSubmit = async (data:any) => {
        // alert('ok');
        // console.log(data);
        
        setLoading(true);
        try {
            const formData = new FormData();
            Object.keys(data).map(key => {
              formData.append(key, data[key]);
            })
            const resData = await createData(formData)
            // console.log(resData);
            if (resData){
              reset()
              navigate('..')
            }
            setLoading(false);
        } catch (error:any) {
            Object.keys(error?.data).map((e:any) => {
                setError(e, {
                type: 'manual', // Use 'manual' for manually triggered errors
                message: error?.data[e],
                });
            })
            setLoading(false);
        }
          
    }

    const initProductsData = async () => {
        try{
            setLoading(true);
            const resData = await initProducts();
            if(resData){
                setLoading(false);
                setProducts(resData.data.products)
            }
            
        }catch(e: any){
            setLoading(false);
            console.log(e);
        }
    }

    const initProductUnits = async (id: number | string) => {
        try{
            setLoading(true);
            const resData = await getProductUnits(id);
            if(resData){
                setLoading(false);
                console.log(resData);
                setUnits(resData.data.units);
                setValue('unit',resData.data.default_unit);
                // setProducts(resData.data.products)
            }
            
        }catch(e: any){
            setLoading(false);
            console.log(e);
        }
    }

    
    useEffect(() => {
        initProductsData()
    }, [])

    return (
        <>
        {loading ? 
        <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
            <LinearProgress color="secondary"/>
        </Box>
        : ''
        }
        
        <Box sx={{ width: '100%' }}>
            <h4>{t('products.create')}</h4>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
                <Controller
                name="product_id"
                control={control}
                render={({ field }) => (
                    <TextField
                    {...field}
                    fullWidth
                    select
                    id="product_id"
                    label={t("product")}
                    variant="filled"
                    error={!!errors.product_id}
                    helperText={errors.product_id?.message}
                    sx={{
                        margin: 0, // Outer margin
                        '& .MuiInputBase-root': {
                          height: '40px', // Input height
                          padding: '0 0px', // Input padding
                          fontSize: '14px'
                        },
                        '& .MuiInputLabel-root': {
                          padding: '0',
                          marginTop: '-5px',
                          fontSize: '14px',
                        },
                      }}
                    onChange={(e)=>{
                        const productId = e.target.value;
                        setValue('product_id',productId);
                        initProductUnits(productId);
                    }}
                    >
                    {products.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                        {option.name}
                        </MenuItem>
                    ))}
                    </TextField>
                )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                name="quantity"
                control={control}
                render={({ field }) => (
                    <TextField
                    {...field}
                    fullWidth
                    id="quantity"
                    label={t("stock")}
                    variant="filled"
                    error={!!errors.quantity}
                    helperText={errors.quantity?.message}
                    sx={{
                        margin: 0, // Outer margin
                        '& .MuiInputBase-root': {
                          height: '40px', // Input height
                          padding: '0 0px', // Input padding
                          fontSize: '14px'
                        },
                        '& .MuiInputLabel-root': {
                          padding: '0',
                          marginTop: '-5px',
                          fontSize: '14px',
                        },
                      }}
                    />
                )}
                />
            </Grid>
            <Grid item xs={6}>
                <label htmlFor="">{t('unit')}</label><br/>
                {units.map((units)=>(
                    <>
                    <label>
                    <input
                        type="radio"
                        value={units.id}
                        {...register('unit')}
                    />
                    {units.text}
                    </label>
                    </>
                ))}
                {errors.unit && <p>{errors.unit.message}</p>}
            </Grid>
                </Grid>
                <Button sx={{ mt: 2 }} variant="contained" onClick={() => navigate(-1)}>
                {t('back')}
                </Button>
                <Button sx={{ mt: 2, ml: 2 }} type="submit" variant="contained" color="success">
                {t('save')}
                </Button>
            </form>
        </Box>
        </>
    )
}

export default observer(StockCreate);